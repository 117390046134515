import actions from "../../store/actions";
import {connect} from "react-redux";
import ReportBlockPage from './ReportBlockPage';

const mapStateToProps = state => ({
    app: state.app,
    comparisonGroups: state.benchmark.comparisonGroups || [],
    user: state.user
});

const mapDispatchToProps = dispatch => ({ 
    getBenchmarkComparisonGroups: () => dispatch(actions.benchmark.getBenchmarkComparisonGroups()),
    getFilteredOrganizationsForReport: (id, filters) => dispatch(actions.report.getFilteredOrganizationsForReport(id, filters)),
    getReportPeriods: (id, organization) => dispatch(actions.report.getReportPeriods(id, organization))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportBlockPage);