import './BenchmarkNew.scss';

import AnalyseList from "./components/AnalyseList";
import history from "../../history";
import React, { useEffect, useState } from "react";
import { Spin } from "antd";

const BenchmarkNew = (props) => {
  const {
    getBenchmarkForms
  } = props;

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);

    getBenchmarkForms()
      .then(() => {})
      .finally(() => setLoader(false));
  }, []);

  const handlerSetFormId = (id) => {
    history.push(`/benchmark/${id}`);
  };

  return (
    <div className={'benchmark-block'}>
      {loader ? (<Spin size={'large'} />) : (
        <>
           <AnalyseList handlerSetFormId={handlerSetFormId} />
        </>
      )}
    </div>
  );
};

export default BenchmarkNew;