import { GET_BENCHMARK_CATEGORIES_SUCCESS, GET_BENCHMARK_CATEGORIES_FETCHING } from '../types'
import { api } from '../../services'

const getBenchmarkCategories = (data) => dispatch => {
  const params = {
    ...data,
  }

  dispatch({
    type: GET_BENCHMARK_CATEGORIES_FETCHING,
    payload: true
  })

  return api.category.getBenchmarkCategories(params)
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_BENCHMARK_CATEGORIES_SUCCESS,
          payload: data.categories
        })
      }
      dispatch({
        type: GET_BENCHMARK_CATEGORIES_FETCHING,
        payload: false
      })
      return data.data
    })
}

export {
  getBenchmarkCategories
}
