import Highcharts from "highcharts";

// Extends the Highcharts CSV export.
// Places the title of the chart on the first row of the CSV export.
(function (H) {
  H.wrap(H.Chart.prototype, 'getCSV', function (proceed) {
      const csv = proceed.apply(this, Array.prototype.slice.call(arguments, 1));
      const title = this.options.title.text || '';
      return `${title}\n${csv}`;
  });
}(Highcharts));