import { api } from "../../services";
import { Button, Checkbox, Input, message, Popconfirm, Progress, Spin, Tag, Tooltip } from "antd";
import { CategoryLinks, CategorySwitch, PeriodSelector, ReopenButton, WebformElement } from "./components";
import classNames from "classnames";
import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Comment } from "@ant-design/compatible";
import ErrorPage from "../ErrorPage/ErrorPage";
import iconChatText from "../../assets/images/svg/chat-text.svg";
import { isArray } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";

import "./SkyQuestionnaire.scss";

const { TextArea } = Input;

function SkyQuestionnaire(props) {
    const [access, setAccess] = useState(true);
    const [activeCategory, setActiveCategory] = useState({});
    const [activeElement, setActiveElement] = useState(null);
    const [categories, setCategories] = useState([]);
    const [canCreate, setCanCreate] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [childSubmissions, setChildSubmissions] = useState([]);
    const [currentCategoryProgress, setCurrentCategoryProgress] = useState(0);
    const [currentPeriod, setCurrentPeriod] = useState(null);
    const [elementOptions, setElementOptions] = useState(null);
    const [elements, setElements] = useState([]);
    const [formActive, setFormActive] = useState(false);
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [loadingChildSubmissions, setLoadingChildSubmissions] = useState(false);
    const [loadingElements, setLoadingElements] = useState(false);
    const [loadingPeriods, setLoadingPeriods] = useState(false);
    const [loadingRemarks, setLoadingRemarks] = useState(false);
    const [loadingSubmission, setLoadingSubmission] = useState(false);
    const [loadingWebform, setLoadingWebform] = useState(false);
    const [parentKey, setParentKey] = useState(null);
    const [periods, setPeriods] = useState([]);
    const [remark, setRemark] = useState({text: ""});
    const [remarkElement, setRemarkElement] = useState(null);
    const [remarks, setRemarks] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [showDetails, setShowDetails] = useState(false);
    const [submission, setSubmission] = useState(null);
    const [updateJobs, setUpdateJobs] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [updatingElements, setUpdatingElements] = useState(0);
    const [webform, setWebform] = useState(null);

    const organisationId = props.user.data.organisation;
    const { t } = props;
    const webformId = props.match.params.id;

    // Retrieves the submission when the webform or period changes
    useEffect(() => {
        fetchSubmission();
    }, [currentPeriod]);

    useEffect(() => {
        if (remarkElement)
            fetchRemarks();

    }, [remarkElement]);

    // useEffect(() => {
    //     fetchChildSubmissions();
    // }, [submission]);

    // Retrieves the categories/elements based on the selected category
    useEffect(() => {
        if (!!!parentKey)
            fetchCategories();
        else
            fetchElements();

        fetchSubmission();

        refreshCategoryPager();
    }, [parentKey, webform]);

    // Retrieves the webform and available periods when the id URL parameter changes
    useEffect(() => {
        if (!!webform && webform.id === webformId) 
            return;

        setLoadingWebform(true);

        setParentKey(null); // Reset selected

        fetchWebform(webformId);
        fetchPeriods(webformId);

        setLoadingWebform(false);
    }, [webformId]);

    const [editingRemark, setEditingRemark] = useState(null);
    const [editRemarkText, setEditRemarkText] = useState({});

    const handleEdit = (remarkId, text) => {
        setEditingRemark(remarkId);
        setEditRemarkText(prev => ({ ...prev, [remarkId]: text }));
    };

    const handleSaveEdit = () => {
        saveRemark();
        setEditingRemark(false);
    };

    // Retrieves the form categories tree
    async function fetchCategories() {
        if (loadingCategories || !!!webform)
            return;

        setLoadingCategories(true);

        await api.questionnaires.getAllCollapseElements(webform.id, parentKey, false, organisationId, currentPeriod)
            .then((result) => {
                setCategories(result.data.webform.elements);
                setElementOptions(result.data.webform.options);
            })
            .catch((e) => console.error("error ", e))
            .finally(() => setLoadingCategories(false));
    }

    async function fetchChildSubmissions() {
        if (loadingChildSubmissions || !!!submission || !!!parentKey || !!!submission.child_organisations.length)
            return;

        setLoadingChildSubmissions(true);

        let children = [];

        submission.child_organisations.forEach(async (org) => {
            await api.questionnaires.getSubmission(webform.id, parentKey, org.id, currentPeriod)
                .then((result) => {
                    children.push(result.data);
                })
                .catch((e) => console.error("error ", e))
                .finally(() => {
                    if (submission.child_organisations.length === children.length) {
                        children.sort((a, b) => a.organisation_id - b.organisation_id);
                        setChildSubmissions(children);
                        setLoadingChildSubmissions(false);
                    }
                });
        });
    }

    // Retrieves the elements in the selected category (parentKey)
    async function fetchElements() {
        if (loadingElements || !!!webform)
            return;

        setLoadingElements(true);

        await api.questionnaires.getElements(webform.id, parentKey, false, organisationId, currentPeriod)
            .then((result) => {
                setElements(result.data.webform.elements);
                setElementOptions(result.data.webform.options);
            })
            .catch((e) => console.error("error ", e))
            .finally(() => setLoadingElements(false));
    }

    // Retrieves the available periods for the current webform
    async function fetchPeriods(id) {
        if (loadingPeriods)
            return;

        setLoadingPeriods(true);

        await props.getSubmissionPeriods({ data: id })
            .then((result) => {
                const data = result?.data?.data;
                if (data) {
                    setCurrentPeriod(moment(data[0]?.condition?.period_start, "YYYY-MM-DD hh:mm:ss").valueOf() / 1000);
                    setPeriods(data);
                }
            })
            .catch((e) => console.error("error ", e))
            .finally(() => setLoadingPeriods(false));
    }

    async function fetchRemarks() {
        setLoadingRemarks(true);

        await api.questionnaires.getRemarksField(webformId, remarkElement.element_indicator)
            .then((res) => {
                setRemarks(res.data.remarks || []);
            })
            .catch((e) => console.error("error ", e))
            .finally(() => setLoadingRemarks(false));
    }

    // Retrieves the webform submission of the current organisation for the selected period
    async function fetchSubmission() {
        if (loadingSubmission || !!!webform || !!!currentPeriod)
            return;

        setLoadingSubmission(true);

        await api.questionnaires.getSubmission(webform.id, parentKey, organisationId, currentPeriod)
            .then((result) => {
                if (result.status == 403)
                    setAccess(false);
                else
                    setSubmission(result.data);
            })
            .catch((e) => console.error("error ", e))
            .finally(() => {
                setLoadingSubmission(false);
                if (parentKey) {
                    changeCategoryProgress(parentKey);
                    fetchChildSubmissions();
                }
            });
    }

    // Retrieves the webform
    async function fetchWebform(id) {
        if (loadingWebform) 
            return;

        await api.questionnaires.getWebform(id)
            .then((result) => {
                setWebform(result.data.webform);
                setFormActive((result.data.webform.third_party_settings?.totta_webform?.data_entry ?? 'active') === 'active');

                if (props.user.data.roles?.includes('administrator') ?? false) {
                    setCanCreate(true);
                    setCanUpdate(true);
                }
                else {
                    if ((result.data.webform.access?.create?.users?.includes(props.user.data.data.id) ?? false) 
                        || (result.data.webform.access?.create?.roles?.some(r => props.user.data.roles?.includes(r) ?? false) ?? false)) {
                        setCanCreate(true);
                    }
                    else {
                        setCanCreate(false);
                    }

                    if ((result.data.webform.access?.update_any?.users?.includes(props.user.data.data.id) ?? false) 
                        || (result.data.webform.access?.update_any?.roles?.some(r => props.user.data.roles?.includes(r) ?? false) ?? false)
                        || (result.data.webform.access?.update_own?.users?.includes(props.user.data.data.id) ?? false) 
                        || (result.data.webform.access?.update_own?.roles?.some(r => props.user.data.roles?.includes(r) ?? false) ?? false)) {
                        setCanUpdate(true);
                    }
                    else {
                        setCanUpdate(false);
                    }
                }
            })
            .catch((e) => console.error("error ", e));
    }

    async function reopen() {
        setShowDetails(false);

        await api.questionnaires.submitForm(webformId, {
            organisation_id: submission.organisation_id,
            period_start: currentPeriod,
            reopen: true,
            submission_id: submission.submission_id
        })
            .then(({data}) => {
                if (!!data.errors && data.errors.length === 1 && 0 in data.errors) {
                    if (data.errors[0].includes("allowed"))
                        message.error(t("webform_save_access_error"));
                    else
                        message.error(data.errors[0]);

                    return;
                }
            })
            .catch((e) => console.error("error ", e))
            .finally(() => fetchSubmission());
    }

    async function handleEditFieldUpdate(remark, text) {
        setEditRemarkText(prev => ({
            ...prev,
            [remark.remark_id]: text,
        }));
        setRemark({
            ...remark,
            text: text
        });
    }

    async function saveRemark() {
        console.log(remark);
        await api.questionnaires.sendRemarksField(webformId, remarkElement.element_indicator, {
            data: remark,
        })
            .then((response) => {
                if (response.data.status) {
                    setRemark({text: ""});
                }
            })
            .then(() => fetchRemarks())
            .catch((e) => console.error("error ", e));
    }

    async function submit() {
        setShowDetails(false);

        if (submission.progress.form.filled_required !== submission.progress.form.count_required) {
            message.error(t('incomplete_message'));
            return;
        }

        await api.questionnaires.submitForm(webformId, {
            finish: true,
            organisation_id: submission.organisation_id,
            period_start: currentPeriod,
            submission_id: submission.submission_id
        })
            .then(({data}) => {
                if (!!data.errors && 'form' in data.errors) {
                    message.error(data.errors.form[0]);
                    return;
                }

                if (!!data.errors && data.errors.length === 1 && 0 in data.errors) {
                    if (data.errors[0].includes("allowed"))
                        message.error(t("webform_save_access_error"));
                    else
                        message.error(data.errors[0]);

                    return;
                }

                message.success(t("save_success"));
            })
            .catch((e) => console.error("error ", e))
            .finally(() => fetchSubmission());
    }

    async function update(job, revalidate_unresolved = false) {
        await api.questionnaires.submitForm(job.webform_id, {
            data: job.data,
            key: job.parentKey,
            main_organisation: job.main_organisation,
            main_submission: job.main_submission,
            organisation_id: job.organisationId,
            overwrite: job.overwrite,
            period_start: job.period,
            submission_id: job.submission_id
        })
            .then(({data}) => {
                if (!!data.errors && data.errors.length === 1 && 0 in data.errors) {
                    if (data.errors[0].includes("allowed"))
                        message.error(t("webform_save_access_error"));
                    else
                        message.error(data.errors[0]);

                    return;
                }
                
                //message.success(t("save_success"));

                if (!!!submission.submission_id && job.main_organisation == job.organisationId) {
                    setSubmission(s => {
                        s.submission_id = data.sid;
                        return s;
                    });
                }
                else {
                    setChildSubmissions(cs => {
                        cs.forEach(c => {
                            if (c.organisation_id == job.organisationId)
                                c.submission_id = data.sid;
                        });
                        
                        return cs;
                    });
                }

                if ('key' in data && parentKey === data.key) {
                    if ('data' in data && !!data.data.length) {
                        setSubmission(s => {
                            s.data.forEach(d => {
                                const updatedData = data.data.find(u => u.sid === s.submission_id && u.key === d.key);
                                if (!!updatedData) {
                                    d.nvh = updatedData.nvh;
                                    d.nvt = updatedData.nvt;

                                    // Autocalculation elements are not automatically updated
                                    // Manually trigger a single-passthrough validation to resolve these values
                                    if(d.value !== updatedData.value && !revalidate_unresolved) {
                                        updateValue(job.organisationId, d.key, d.value, true);
                                    }

                                    if ('value' in updatedData) {
                                        d.value = updatedData.value;
                                        d.overwritten = updatedData.overwritten ?? false;
                                    }
                                }
                            });

                            const updatedData = data.data.filter(x => x.sid === s.submission_id);
                            updatedData.forEach(x => {
                                if (!!!s.data.find(y => y.key === x.key)) {
                                    s.data.push({
                                        key: x.key,
                                        nvh: x.nvh,
                                        nvt: x.nvt,
                                        overwritten: x.overwritten ?? false,
                                        value: x.value,
                                        isDefaultValue: false
                                    });
                                }
                            });
    
                            return s;
                        });

                        setChildSubmissions(cs => {
                            cs.forEach(c => {
                                c.data.forEach(d => {
                                    const updatedChildData = data.data.find(u => u.sid === c.submission_id && u.key === d.key);
                                    if (!!updatedChildData) {
                                        d.nvh = updatedChildData.nvh;
                                        d.nvt = updatedChildData.nvt;
                                        d.overwritten = updatedChildData.overwritten ?? false;
                                        d.value = updatedChildData.value;
                                    }
                                });

                                const updatedChildData = data.data.filter(x => x.sid === c.submission_id);
                                updatedChildData.forEach(x => {
                                    if (!!!c.data.find(y => y.key === x.key))
                                        c.data.push({
                                            isDefaultValue: false,
                                            key: x.key,
                                            nvh: x.nvh,
                                            nvt: x.nvt,
                                            overwritten: x.overwritten ?? false,
                                            value: x.value
                                        });
                                });
                            });
                            
                            return cs;
                        });
                    }
    
                    if ('progress' in data) {
                        setSubmission(s => {
                            s.progress = data.progress;
                            return s;
                        });
                        changeCategoryProgress(parentKey);
                    }

                    if ('elements' in data) {
                        setElements(e => {
                            e.forEach(element => {
                                element.is_visible = data.elements[element.webform_key].is_visible;
                            });

                            return e;
                        });

                        let temp_cats = categories;
                        setCategories(c => {
                            c.forEach(cat => {
                                cat.states = data.elements[cat.webform_key].states;
                                cat.is_visible = data.elements[cat.webform_key].is_visible;

                                if (!!cat.elements) {
                                    cat.elements.forEach(sc => {
                                        sc.states = data.elements[sc.webform_key].states;
                                        sc.is_visible = data.elements[sc.webform_key].is_visible;
                                    });
                                }
                            });

                            temp_cats = c;
                            return c;
                        });

                        refreshCategoryPager(temp_cats);
                    }

                    if ('errors' in data) {                        
                        if (data.sid === submission?.submission_id) {
                            setSubmission(s => {
                                s.validation.errors = data.errors;
                                return s;
                            });
                        }
                        else {
                            setChildSubmissions(cs => {
                                cs.forEach(c => {
                                    if (c.submission_id === data.sid)
                                        c.validation.errors = data.errors;
                                });

                                return cs;
                            });
                        }
                    }
                    else {
                        if (data.sid === submission?.submission_id) {
                            setSubmission(s => {
                                s.validation.errors = null;
                                return s;
                            });
                        }
                        else {
                            setChildSubmissions(cs => {
                                cs.forEach(c => {
                                    if (c.submission_id === data.sid)
                                        c.validation.errors = null;
                                });

                                return cs;
                            });
                        }
                    }

                    if ('warnings' in data) {
                        if (data.sid === submission?.submission_id) {
                            setSubmission(s => {
                                s.validation.warnings = data.warnings;
                                return s;
                            });
                        }
                        else {
                            setChildSubmissions(cs => {
                                cs.forEach(c => {
                                    if (c.submission_id === data.sid)
                                        c.validation.warnings = data.warnings;
                                });

                                return cs;
                            });
                        }
                    }
                    else {
                        if (data.sid === submission?.submission_id) {
                            setSubmission(s => {
                                s.validation.warnings = null;
                                return s;
                            });
                        }
                        else {
                            setChildSubmissions(cs => {
                                cs.forEach(c => {
                                    if (c.submission_id === data.sid)
                                        c.validation.warnings = null;
                                });

                                return cs;
                            });
                        }
                    }
                }
            })
            .catch((e) => console.error("error ", e))
            .finally(async () => {
                setUpdatingElements(x => x - 1);
                let jobs = updateJobs;
                if (jobs.length) {
                    const nextJob = jobs.shift();
                    setUpdateJobs(jobs);
                    await update(nextJob);
                }
                else {
                    setUpdating(false);
                }
            });
    }

    async function updateNVH(organisationId, elementKey, nvh) {
        const submission_id = submission.organisation_id === organisationId ? submission.submission_id : childSubmissions.find(c => c.organisation_id === organisationId).submission_id;
        const newJob = {
            data: [{key: elementKey, nvh: !!nvh}],
            parentKey: parentKey,
            main_organisation: submission.organisation_id,
            main_submission: submission.submission_id,
            organisationId: organisationId,
            overwrite: organisationId != submission.organisation_id,
            period: currentPeriod,
            submission_id: submission_id,
            webform_id: webform.id
        };

        setUpdatingElements(x => x + 1);

        if (updating) {
            setUpdateJobs(jobs => {
                jobs.push(newJob);
                return jobs;
            });
        }
        else {
            setUpdating(true);
            update(newJob);
        }
    }

    async function updateNVT(organisationId, elementKey, nvt) {
        const submission_id = submission.organisation_id === organisationId ? submission.submission_id : childSubmissions.find(c => c.organisation_id === organisationId).submission_id;
        const newJob = {
            data: [{key: elementKey, nvt: !!nvt}],
            parentKey: parentKey,
            main_organisation: submission.organisation_id,
            main_submission: submission.submission_id,
            organisationId: organisationId,
            overwrite: organisationId != submission.organisation_id,
            period: currentPeriod,
            submission_id: submission_id,
            webform_id: webform.id
        };

        setUpdatingElements(x => x + 1);
        if (updating) {
            setUpdateJobs(jobs => {
                jobs.push(newJob);
                return jobs;
            });
        }
        else {
            setUpdating(true);
            update(newJob);
        }
    }

    async function updateValue(organisationId, elementKey, value, revalidate_unresolved = false) {
        const submission_id = submission.organisation_id === organisationId ? submission.submission_id : childSubmissions.find(c => c.organisation_id === organisationId).submission_id;
        if (isArray(value))
            value = value.toString();

        const newJob = {
            data: [{key: elementKey, value: value}],
            parentKey: parentKey,
            main_organisation: submission.organisation_id,
            main_submission: submission.submission_id,
            organisationId: organisationId,
            overwrite: organisationId != submission.organisation_id,
            period: currentPeriod,
            submission_id: submission_id,
            webform_id: webform.id
        };
        
        setUpdatingElements(x => x + 1);
        if (updating) {
            setUpdateJobs(jobs => {
                jobs.push(newJob);
                return jobs;
            });
        }
        else {
            setUpdating(true);
            update(newJob, revalidate_unresolved);
        }
    }

    async function handleDeleteRemark(remarkId) {
        await api.questionnaires.deleteRemarksField(webformId, remarkElement.element_indicator, remarkId)
            .then((response) => {
                if (response.data.status) {
                    console.log('status', response.data.status);
                }
            })
            .then(() => fetchRemarks())
            .catch((e) => console.error("error ", e));
    }

    const changeCategoryProgress = async (categoryKey) => {
        const currentProgress = submission?.progress?.elements?.find(e => e.key === categoryKey);
        if (currentProgress === null || currentProgress === undefined)
            return 0;

        let prog = +parseFloat((100 / currentProgress.count) * currentProgress.filled).toFixed(1);

        setCurrentCategoryProgress(prog);
    }

    const refreshCategoryPager = () => {
        const current_categories = categories.filter(c => !c.states.includes('invisible'));
        if (!!current_categories.length && !!parentKey) {
            let cats = [];
            categories.forEach(c => {
                const subcats = c.elements.filter(e => !e.states.includes('invisible'));
                if (!!subcats)
                    cats = cats.concat(subcats);
                else
                    cats = cats.concat([c]);
            });

            const currentCat = getCategoryByKey(parentKey);
            
            let nextCat = cats.find((e, i, cs) => (i - 1) >= 0 && cs[i - 1].webform_key === parentKey);
            let previousCat = cats.find((e, i, cs) => (i + 1) < cs.length && cs[i + 1].webform_key === parentKey);
            
            setActiveCategory({
                current: currentCat,
                next: nextCat?.states?.includes('invisible') ? null : nextCat,
                previous: previousCat?.states?.includes('invisible') ? null : previousCat
            });
        }
        else {
            setActiveCategory({
                current: null,
                next: null,
                previous: null
            });
        }
    }



    const getCategoryByKey = (key) => {
        if (key === null || key === '')
            return null;

        let resultCategory = null;
        categories.forEach(cat => {
            if (cat.webform_key === key)
                resultCategory = cat;                
                
            if (cat.webform_children.includes(key))
                resultCategory = cat.elements.find(sc => sc.webform_key === key);
        });

        return resultCategory;
    }

    const getFirstCategory = () => {
        if (categories.length === 0)
            return null;

        let currentCategory = categories[0];
        while (currentCategory.sub_elements_count.category_count > 0) {
            currentCategory = currentCategory.elements[0];
        }

        return currentCategory;
    }

    return (
        <>
            {!access ? (
                <ErrorPage/>
            ) : ((loadingWebform || webform === null) ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spin />
                </div>
            ) : (
                <>
                    {!!updatingElements && (
                        <div className="elementUpdater">
                            <span>Updating {updatingElements} {(updatingElements > 1 ? "values" : "value")}</span>
                            <Spin/>
                        </div>
                    )}

                    <div className="titleQuestionnaire">
                        <div className="titleQuestionnaire_left">
                            <h1 className="titel">{webform.title}</h1>
                            <div>
                                {loadingSubmission || !!!submission ? (
                                    <></>
                                ) : (
                                    <>
                                        <img alt="" src={iconChatText} style={{ marginRight: 10 }} />
                                        <span style={{ marginRight: 30 }}>
                                            {submission?.progress?.form?.count_input ?? 0} {t("general:questions")} ({submission?.progress?.form?.count_required ?? 0}<span style={{ color: "red" }}>*</span>)
                                        </span>
                                        <Progress 
                                            percent={submission?.progress?.form ? +parseFloat((100 / submission?.progress?.form.count_input) * submission?.progress?.form.filled).toFixed(1): 0} 
                                            showinfo={"true"} 
                                            size="small" 
                                            status="active" 
                                            style={{ width: 150, marginRight: 30 }} />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="titleQuestionnaire_right">
                            <PeriodSelector loading={loadingSubmission} period={currentPeriod} periodChanged={setCurrentPeriod} periods={periods} />
                            {(!!formActive && (!!canCreate || (!!submission?.completed && !!canUpdate))) && (
                                submission?.completed ? (
                                    <ReopenButton canReopen={submission?.permissions?.can_reopen} canUpdate={canUpdate} formActive={formActive} loadingSubmission={loadingSubmission} reopen={reopen} />
                                ) : (
                                    <Button 
                                        disabled={loadingSubmission || !!!submission} 
                                        onClick={() => setParentKey(getFirstCategory().webform_key)} 
                                        style={{backgroundColor: "#134176", borderRadius: 21, height: 35, width: 195}} 
                                        type="primary">
                                        {t("start_filling")}
                                    </Button>
                                )
                            )}
                        </div>
                    </div>

                    <div className={classNames({"qs-details": true, "qs-details-shown": showDetails})} >
                        <div className="iconClose" onClick={() => setShowDetails(false)}>
                            <CloseOutlined style={{ fontSize: 15 }} />
                        </div>

                        <div className="containerDetails">
                            <span style={{display: "block", marginBottom: "0.5em"}}>{remarkElement?.webform_indicator}</span>

                            {remarkElement?.description && (
                                <div className="title" dangerouslySetInnerHTML={{__html: remarkElement.description || ""}}/>
                            )}

                            {(!!remarkElement?.nvt || !!remarkElement?.nvh) && (
                                <div>
                                    {!!remarkElement?.nvt && (
                                        <div style={{display: "flex", gap: ".5em"}}>
                                            <Checkbox checked={!!submission?.data?.find(d => d.key === remarkElement.webform_key)?.nvt} onChange={e => updateNVT(submission.organisation_id, remarkElement.webform_key, e.target.checked)} />
                                            <label>{t("nvt")}</label>
                                        </div>
                                    )}
                                    {!!remarkElement?.nvh && (
                                        <div style={{display: "flex", gap: ".5em"}}>
                                            <Checkbox checked={!!submission?.data?.find(d => d.key === remarkElement.webform_key)?.nvh} onChange={e => updateNVH(submission.organisation_id, remarkElement.webform_key, e.target.checked)} /> 
                                            <label>{t("nvh")}</label>
                                        </div>
                                    )}
                                </div>
                            )}

                            {loadingRemarks ? (
                                <div className="qs-loading-overlay">
                                    <Spin />
                                </div>
                            ) : (
                                <div className="qs-details-comment">
                                    <div className='qs-details-label'>{t('comment')}</div>

                                    <TextArea
                                        className="detail_textArea"
                                        defaultValue={remark.text}
                                        onChange={(e) => setRemark(r => {
                                            r.text = e.target.value;
                                            return r;
                                        })}/>

                                        <div className="containerButton">
                                        <Button type="default" onClick={saveRemark}>
                                            {t("add_remark")}
                                        </Button>
                                    </div>
            
                                    {remarks.map((item) => (
                                        <Comment 
                                        key={item.remark_id}
                                        actions={
                                            item.user === props.user?.data?.data?.display_name
                                                ? [editingRemark === item.remark_id ? 
                                                    <div>
                                                        <Button type="default" disabled={!remark.remark_id} onClick={() => handleSaveEdit()}>{t("save")}</Button>
                                                        <Button className="cancel-update-remark" onClick={() => setEditingRemark(false)} type="danger">Cancel</Button>
                                                    </div>
                                                    :   <div className="containerButton" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <Button type="default" onClick={() => handleEdit(item.remark_id, item.text)}>{t("edit_remark")}</Button>
                                                            <DeleteOutlined 
                                                                style={{ color: "red", cursor: "pointer", fontSize: "16px", marginLeft: "45px" }} 
                                                                onClick={() => handleDeleteRemark(item.remark_id)} 
                                                            />
                                                        </div>] 
                                                : []
                                        }    
                                        author={<a>{item.user}</a>} 
                                        content={
                                            editingRemark === item.remark_id ? 
                                            <div>
                                                <Input.TextArea 
                                                    value={editRemarkText[item.remark_id] || ""} 
                                                    onChange={(e) => handleEditFieldUpdate(item, e.target.value)}
                                                    autoSize={{ minRows: 1, maxRows: 4 }}
                                                /> 
                                            </div>
                                            :   <div>
                                                    <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                                </div>
                                        } 
                                        datetime={
                                            <Tooltip title={moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss")}>
                                                <span>{moment(item.updated_at).fromNow()}</span>
                                            </Tooltip>
                                        }
                                    />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    {(loadingCategories || loadingSubmission || !!!submission) ? (<Spin size="large"/>) : (!!parentKey ? (
                        <>
                            <div className="containerModalTest">
                                <div className="modalHeader">
                                    <div className="logo-box">
                                        <Link to="/" className="logo text-center">
                                            <span className="logo-lg">
                                                <img alt="" height="70" src={props.app.settings.logo} style={{ display: "block" }}/>
                                            </span>
                                        </Link>
                                    </div>

                                    <div className="contentHeader">
                                        <Tooltip placement="left" title={webform.title}>
                                            <div className="modalTitle">{webform && webform.title.length <= 36 ? webform.title : webform.title.slice(0, 36) + "..."}</div>
                                        </Tooltip>

                                        <div className="containerButton">
                                            {submission?.completed ? (
                                                <Popconfirm
                                                    cancelText={t("general:no")}    
                                                    disabled={loadingSubmission || !!!submission || !submission.permissions.can_reopen || !formActive || !canUpdate}
                                                    okText={t("general:yes")}
                                                    onConfirm={() => reopen()}    
                                                    title={<div dangerouslySetInnerHTML={{ __html: t("be_careful") }}/>}>

                                                    <Button disabled={loadingSubmission || !!!submission || !submission.permissions.can_reopen || !formActive || !canUpdate} type="default">
                                                        {t("reopen")}
                                                    </Button>
                                                </Popconfirm>
                                            ) : (
                                                <Button
                                                    disabled={!!!submission || updatingElements > 0 || (submission.validation?.errors !== null && submission.validation?.errors !== undefined) || submission.progress.form.filled_required !== submission.progress.form.count_required || submission.progress.basic.filled_required !== submission.progress.basic.count_required || !formActive || !(canCreate || canUpdate)}
                                                    onClick={() => submit()}
                                                    type="default">
                                                    {t("submit")}
                                                </Button>
                                            )}

                                            <Button onClick={() => {setParentKey(null); setShowDetails(false);}} style={{alignItems: "center", border: "none", display: "flex"}} type="link">
                                                <CloseOutlined style={{ color: "#21416f", fontSize: 20 }} />
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="containerProgressbar">
                                        <Progress
                                            percent={currentCategoryProgress}       
                                            showInfo={false}
                                            status="active"
                                            strokeWidth={5}/>
                                    </div>
                                </div>

                                <div className="buttonBurger" onClick={() => setSidebarVisible(!sidebarVisible)} style={{color: "#134176", display: sidebarVisible ? "none" : "flex", fontSize: 18}}>
                                    <RightOutlined />
                                </div>

                                <div className="containerSidebar" style={{ display: sidebarVisible ? "block" : "none" }}>
                                    <div className="backToQuestionnaires">
                                        <Link to="/questionnaires">
                                            <LeftOutlined style={{ marginRight: 14 }} /> {t("home")}
                                        </Link>
                                    </div>

                                    <div className="showSidebar" onClick={() => setSidebarVisible(!sidebarVisible)}>
                                        <span>{t("index")}</span>
                                        <LeftOutlined style={{ fontSize: 18, color: "#134176" }} />
                                    </div>
                                    <div className="containerLists">
                                        {/* {categories && categories.map(cat => (
                                            <CategoryLink activeCategoryKey={parentKey} category={cat} key={cat.webform_key} onClick={setParentKey} />
                                        ))} */}
                                        <CategoryLinks categories={categories} parentKey={parentKey} setParentKey={setParentKey} updating={updating}/>
                                    </div>
                                </div>

                                <div className={classNames({"containerContent": true, "showing-details": showDetails})} style={{width: sidebarVisible ? "calc(100vw - 215px)" : "100vw", left: sidebarVisible ? 215 : 0}}>
                                    {/* {!!this.props.user.data.tags.length && (
                                        <div className="containerFillter">
                                            <div className="leftContainer">
                                                <span className="title">{t("filter_by_tag")}: </span>
                                                {tags.filter((tag) => this.props.user.data.tags.includes(tag.id)).map((tag) => (
                                                    <Tag.CheckableTag checked={selectedTags.includes(tag.id)} key={tag.id} onChange={(checked) => this.handleChangeTags(tag.id, checked)}>
                                                        {tag.name}
                                                    </Tag.CheckableTag>
                                                ))}
                                            </div>
                                        </div>
                                    )} */}

                                    <div className="containerMainQuestionnaire">
                                        <div className="organization__info">
                                            <p> 
                                                Organization info: <br />
                                                {submission ? `ID: ${submission.organisation_id} / Title: ${submission.organisation_title}` : ""}
                                            </p>

                                            <PeriodSelector loading={loadingSubmission} period={currentPeriod} periodChanged={setCurrentPeriod} periods={periods} />
                                        </div>

                                        {loadingElements ? (
                                            <Spin size="large"/>
                                        ) : (
                                            <>
                                                <CategorySwitch next={activeCategory.next} onClick={setParentKey} previous={activeCategory.previous} />

                                                <div>
                                                    {elements?.map((el) => {
                                                        return (
                                                            <WebformElement 
                                                                active={activeElement === el.webform_key}
                                                                childSubmissions={childSubmissions}
                                                                disabled={!formActive || !(canCreate || canUpdate) || (!!activeCategory?.current?.states && (activeCategory.current.states.includes('disabled') || activeCategory.current.states.includes('readonly')))}
                                                                element={el} 
                                                                key={el.webform_key} 
                                                                loadingChildSubmissions={loadingChildSubmissions}
                                                                loadingSubmission={loadingSubmission}
                                                                onChange={updateValue}
                                                                onClick={(e) => {setActiveElement(e); setRemarkElement(e); setShowDetails(!!e.description || !!e.nvt || !!e.nvh);}}
                                                                options={elementOptions}
                                                                showDetailsClick={(e) => {setRemarkElement(e); setShowDetails(true);}}
                                                                submission={submission}/>
                                                        );
                                                    })}
                                                </div>

                                                <CategorySwitch next={activeCategory.next} onClick={setParentKey} previous={activeCategory.previous} />
                                            </> 
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>  
                    ) : (
                        categories?.map((el) => {
                            return (
                                <WebformElement 
                                    active={activeElement === el.webform_key}
                                    disabled={!formActive || !(canUpdate || canCreate)}
                                    element={el} 
                                    key={el.webform_key} 
                                    loadingSubmission={loadingSubmission}
                                    onClick={setParentKey}
                                    options={elementOptions}
                                    submission={submission}/>
                            );
                        })
                    ))}
                </>
            ))}
        </>
    );
}

export default withTranslation("questionnaire")(SkyQuestionnaire);