import './ModalComparisonGroup.scss';

import { api } from "../../services";
import { Checkbox, Input, message, Spin, Switch, Tooltip } from "antd";
import { LevelFilter, SegmentationFilters } from "../../components";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

function ModalComparisonGroup(props) {
  const {
    activeOrganization,
    appSettings,
    benchmarkForms,
    closeModal,
    comparisonGroupEditId,
    getBenchmarkComparisonGroups,
    getFilteredOrganizationForBenchmark,
    handleActionWithGroup,
    indicatorFilterData,
    isSearchLoader,
    modalEdit,
    organization,
    organizationsInEditGroup,
    reportPage = false,
    searchByAllOrganizations,
    searchOrganizationData,
    settingsBenchmark,
    t,
    visible
  } = props;

  const { id } = useParams();

  const [activeFilters, setActiveFilters] = useState({levelFilters: [], segmentationFilters: []});
  const [comparisonGroup, setComparisonGroup] = useState([]);
  const [comparisonGroupName, setComparisonGroupName] = useState(null);
  const [filterSteps, setFilterSteps] = useState(0);
  const [indicatorsData, setIndicatorsData] = useState([]);
  const [levelFilters, setLevelFilters] = useState(null);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [numberOfGroup, setNumberOfGroup] = useState(settingsBenchmark?.minimum_number_organisations);
  const [options, setOptions] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [reportPageIndicatorData, setReportPageIndicatorData] = useState(null);
  const [reportPageLoader, setReportPageLoader] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedOrganisations, setSelectedOrganisations] = useState([]);

  // Loads the filtered organizations when the filters or searchvalues change
  useEffect(() => {
    setLoadingOrganizations(true);

    getFilteredOrganizationForBenchmark(id, activeFilters)
        .then((response) => {
          setOptions(response.data.values);

          if (organizations.length === 0)
            setOrganizations(response.data.values);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingOrganizations(false));
  }, [activeFilters, searchValue]);

  useEffect(() => {
    setComparisonGroupName(modalEdit || null);
    handlerAddGroups();

    if (benchmarkForms?.length === 1) {
      setNextStep(2, benchmarkForms?.[0]?.id)
    }

    api.benchmark.getBenchmarkLevels()
      .then((response) => {
        const { data: { terms } } = response;
        setLevelFilters(terms);
      })
      .catch((e) => console.error(e))
  }, [modalEdit]);

  useEffect(() => {
    if (modalEdit && organizationsInEditGroup?.length) {
      const checkedGroupData = organizationsInEditGroup?.map(item => {
        return {
          'id': item.id,
          'title': item.name,
        }
      });

      setComparisonGroup(checkedGroupData);
    }
  }, [organizationsInEditGroup]);

  useEffect(() => {
    searchByAllOrganizations("");
  }, []);

  // Resets the modal state when it becomes invisible
  useEffect(() => {
    if (!visible) {
      searchByOrganizations('');
      setSearchValue('');
      setComparisonGroup([]);
      handlerSelectAllOrganization(false);
    }
  }, [visible]);

  // Handles the checkbox selection event, which adds the selected organization ID to the selection list.
  const handlerChecked = (v) => {
    if (!!!v)
      return;

    if (!!selectedOrganisations?.find(item => item === v))
      setSelectedOrganisations(selectedOrganisations.filter(item => item !== v));
    else
      setSelectedOrganisations([...selectedOrganisations, v]);
  }

  const setNextStep = (step, id = false) => {
    setFilterSteps(step);
    if (id) {
      setReportPageLoader(true);
      api.questionnaires.getSegmentationElements(id)
        .then((response) => {
          const { status, data } = response;
          if (status === 200) {
            setReportPageIndicatorData(data?.webform);
          }
        })
        .catch((e) => console.error(e))
        .finally(() => setReportPageLoader(false));
    } else {
      setReportPageIndicatorData(null);
    }
  }

  // Orders the organizations by title and filters them using the searchValue
  const getOrganisations = () => {
    let organizations = options
      .sort((x, y) => x.title.localeCompare(y.title)); // Sort by title

    if(searchValue)
      organizations = organizations?.filter((item) => item.title.search(new RegExp(searchValue, 'i')) !== -1);

    return organizations;
  };

  const renderCheckBoxItems = useMemo(() => {
    return (
      <Checkbox.Group
        className={'group-editor-selection-list'}
        value={selectedOrganisations}
      >
        {getOrganisations()?.map((item) => (
          <Tooltip key={item.id} placement="left" title={item.title} zIndex={20}>
            <Checkbox
              onChange={(e) => handlerChecked(e.target.value)}
              value={item.id}
              key={item.id}>
              {item.title}
            </Checkbox>
          </Tooltip>
        ))}
      </Checkbox.Group>
    )
  }, [selectedOrganisations, options]);

  const handlerCloseModal = () => {
    searchByOrganizations('');
    setComparisonGroup([]);
    setSearchValue('');
    setSelectedOrganisations([]);
    handlerSelectAllOrganization(false);
    closeModal();
  }

  const comparisonGroupActions = (url, id = '', data, messageText) => {
    const currentOrganizations = comparisonGroup?.map(item => item.id);

    if (comparisonGroupName && (currentOrganizations?.length >= numberOfGroup)) {
      id
        ? url(id, data).then((response) => {
          const { status } = response;

          if (status < 300) {
            getBenchmarkComparisonGroups();
            message.success(messageText)
          }
        })
          .finally(() => {
            setComparisonGroupName(null);
            handlerCloseModal();
          })
        : url(data).then((response) => {
          const { status } = response;

          if (status < 300) {
            getBenchmarkComparisonGroups();
            message.success(messageText)
          }
        })
          .finally(() => {
            setComparisonGroupName(null);
            handlerCloseModal();
          })
    } 
    else {
      message.error(`Er moeten minimaal ${numberOfGroup} organisaties in de vergelijkingsgroep zitten`);
    }
  }

  const createComparisonGroup = () => {
    comparisonGroupActions(
      api.benchmark.createComparisonGroup,
      null,
      { organisations: comparisonGroup?.map(item => item.id + ''), name: comparisonGroupName },
      `${t('comparisongroup_create_success')} ${comparisonGroupName}!`);
  };

  const editComparisonGroup = () => {
    comparisonGroupActions(
      api.benchmark.updateComparisonGroup,
      comparisonGroupEditId,
      {ids: comparisonGroup?.map(item => item.id + ''), name: comparisonGroupName},
      `${t('comparisongroup_edit_success')} ${comparisonGroupName}!`
    );
  };

  const deleteCheckedGroup = (value) => {
    const newComparisonGroup = comparisonGroup?.filter(item => item.id !== value.id);
    setComparisonGroup(newComparisonGroup);
  }

  const handlerAddGroups = () => {
    setComparisonGroup([...comparisonGroup].concat(organizations?.filter(item => !!!comparisonGroup.find(o => o.id === item?.id) && !!selectedOrganisations?.find(value => value === item?.id))));
    setSelectedOrganisations([]);
  };

  const handlerReplaceGroups = () => {
    let currentComparisonGroup = [];

    const data = options?.map((item) => {
      const current = selectedOrganisations?.find(value => value === item?.id);
      return current && currentComparisonGroup.push(item);
    });

    setComparisonGroup(currentComparisonGroup);
    setSelectedOrganisations([]);
  }

  // Handles the switch event that selects or deselects all organizations
  const handlerSelectAllOrganization = (checked) => {
    if (checked)
      setSelectedOrganisations(getOrganisations()?.map(item => item.id));
    else
      setSelectedOrganisations([]);
  }

  async function onLevelFilterChanged(levelFilters) {
    setLoadingOrganizations(true);

    const updatedFilters = {
      ...activeFilters,
      levelFilters: levelFilters
    };

    setActiveFilters(updatedFilters);
  }

  async function onSegmentationFiltersChanged(segmentationFilters) {
    setLoadingOrganizations(true);

    const updatedFilters = {
      ...activeFilters,
      segmentationFilters: segmentationFilters
    };

    setActiveFilters(updatedFilters);
  };

  const searchByOrganizations = (value, indicators = [], levels = []) => {
    setSearchValue(value);

    let newIndicatorsData = {};

    indicators.forEach(item => {
      for (let key in item) {
        newIndicatorsData[`${key}`] = item[key];
      }
    });

    const organisationLevel = levels?.map(item => item.id);

    const params = {
      site_id: appSettings.site_id,
      url: appSettings.calculation_url,
      q: value,
      indicators: newIndicatorsData,
      organisation_levels: organisationLevel,
    }
    searchByAllOrganizations(params);
  }

  const handlerInputSearchData = (e) => {
    setSearchValue(e.target.value);
  }

  return (
    <div className={'group-editor'}>
      <div className={'group-editor-columns'}>

        <div className={'group-editor-column'}>
          <p className={'group-editor-column-title'}><span>1.</span> {t('organization_search')}</p>
          <Input.Search
            allowClear={true}
            className={'group-editor-column-content group-editor-input'}
            onChange={handlerInputSearchData}
            onSearch={(value) => searchByOrganizations(value, indicatorsData)}
            placeholder={t('search')}
            size={'large'}
            value={searchValue}
          />

          <p style={{fontWeight: "600", marginTop: "1em"}}>{t('filters')}</p>
          <div id={'segmentation-filters-comparison-group'} style={{maxHeight: "600px", overflowY: 'auto'}}>
            <LevelFilter levels={levelFilters} onLevelFiltersChanged={onLevelFilterChanged} popoverId={'segmentation-filters-comparison-group'} />
            <SegmentationFilters onActiveFiltersChanged={onSegmentationFiltersChanged} popoverId={'segmentation-filters-comparison-group'} segmentationFilters={indicatorFilterData} />
          </div>
        </div>

        <div className="group-editor-column">
          <p className={'group-editor-column-title'}><span>2.</span> {t('search_result')}</p>
          <p className={'group-editor-column-subtitle'} style={{display: "flex", justifyContent: "space-between"}}>
            <span>{getOrganisations()?.length ?? 0} {t('organizations')}</span>
            <span>{selectedOrganisations?.length ?? 0} {t('selected')}</span>
          </p>

          <div className={'group-editor-switch'}>
            <Switch onChange={handlerSelectAllOrganization} />
            <span>{t('select_all')}</span>
          </div>

          {loadingOrganizations ? <Spin size={'large'} style={{ padding: '20px' }} /> : renderCheckBoxItems}

          <div style={{alignItems: "center", display: "flex", flexDirection: "column"}}>
            <button className={'group-editor-action'} onClick={handlerAddGroups}>{t('add_to_comparison_groups')}</button>
            <button className={'group-editor-action'} onClick={handlerReplaceGroups}>{t('replace_comparison_groups')}</button>
          </div>
        </div>

        <div className="group-editor-column group-editor-comparison">
          <p className={'group-editor-column-title'}><span>3.</span> {t('my_comparison_group')}</p>
          <p className={'group-editor-column-subtitle'}>{comparisonGroup?.length} {t('organizations')}</p>
          
          {comparisonGroup?.length > 0 && (
            <span className={'group-editor-action-clear'} onClick={() => setComparisonGroup([])}>
              {t('сlear_all')}
            </span>
          )}

          <ul className={'group-editor-active-list'}>
            {comparisonGroup?.sort((x, y) => x.title.localeCompare(y.title)).map((item, index) => (
              <li key={index}>
                <div className={'group-editor-action-delete'} onClick={() => deleteCheckedGroup(item)} />
                <span>{item.title}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="group-editor-column">
          <p className={'group-editor-column-title'}><span>4.</span> {t('beepal_comparison_group_name')}</p>
          <div className="group-editor-column-content">
            <p className={'group-editor-description'}>{t('name_in_reports')}</p>
            <input
              className={'group-editor-name-input group-editor-input'}
              onChange={(e) => setComparisonGroupName(e.target.value)}
              placeholder={t('comparison_group')}
              value={comparisonGroupName || ''}
            />
          </div>
        </div>
      </div>

      <div className={'group-editor-modal-actions-wrapper'}>
        <div className={'group-editor-modal-actions'}>
          <button className={'group-editor-modal-cancel'} onClick={handlerCloseModal}>{t('cancel')}</button>
          {
            modalEdit
              ? <button
                className={'button__action'}
                disabled={!comparisonGroupName}
                onClick={editComparisonGroup}
              >{t('save_group')}</button>
              : <button
                className={'button__action'}
                disabled={!comparisonGroupName}
                onClick={createComparisonGroup}
              >{t('save_group')}</button>
          }
        </div>
      </div>
    </div>
  );
};

export default withTranslation('benchmark')(ModalComparisonGroup);