import http from './http'
import axios from 'axios'

const submitForm = (webformId, data) => {
  return http({
    url: `/api/v1/webform/${webformId}/submission?_format=json`,
    method: 'POST',
    data
  });
}

const getSubmission = async (webformId, parentKey, organisationID, periodStart = false) => {
  let url = `/api/v1/webform/${webformId}/submission?_format=json${organisationID ? `&organisation_id=${organisationID}` : ''}`;

  if (parentKey)
    url += `&key=${parentKey}`;

  if (periodStart)
    url += `&period_start=${periodStart}`;

  return http({url, method: 'GET'});
}

const getSubmissions = (webformId, parentKey, organisationIDs, periodStart = false) => {
  let url = `/api/v1/webform/${webformId}/webformsubmissions?_format=json${organisationIDs.length > 0 ? `&organisation_ids=${organisationIDs.join(',')}` : ''}`;

  if (parentKey)
    url += `&key=${parentKey}`;

  if (periodStart)
    url += `&period_start=${periodStart}`;

  return http({url, method: 'GET'});
}

const getElements = (webformId, parentKey, sid, organisationID, periodStart = false) => {
  let url = `/api/v1/webform/${webformId}/internal-elements?_format=json${organisationID ? `&organisation_id=${organisationID}` : ''}`;

  const queryParams = [];

  if (parentKey)
    queryParams.push({ key: 'key', value: parentKey });

  if (periodStart)
    url += `&period_start=${periodStart}`;

  if (sid)
    queryParams.push({ key: 'sid', value: sid });

  if (queryParams.length)
    url += `&${queryParams.map(i => `${i.key}=${i.value}`).join('&')}`;

  return http({url, method: 'GET'});
}

const getAllCollapseElements = (webformId, parentKey, sid, organisationID = false, periodStart = false) => {
  let url = `/api/v1/webform/${webformId}/all-categories?_format=json`;

  const queryParams = [];

  if (parentKey)
    queryParams.push({ key: 'key', value: parentKey });

  if (periodStart)
    url += `&period_start=${periodStart}`;

  if (organisationID)
    url += `&organisation_id=${organisationID}`;

  if (sid)
    queryParams.push({ key: 'sid', value: sid });

  if (queryParams.length)
    url += `&${queryParams.map(i => `${i.key}=${i.value}`).join('&')}`;

  return http({url, method: 'GET'});
}

const getAllElements = webformId => {
  return http({
    url: `/api/v1/webform/${webformId}/all-elements?_format=json`,
    method: 'GET'
  });
}

const getSegmentationElements = webformId => {
  return http({
    url: `/api/v1/webform/${webformId}/segmentation-elements?_format=json`,
    method: 'GET'
  });
}

const getForms = (search) => {
  return http({
    url: `/api/v1/webform/list${search ? `?search=${search}` : ''}`,
    method: 'GET'
  });
}

const getProgressForm = (webformId, organisationID) => {
  return http({
    url: `/api/v1/webform/${webformId}/settings?_format=json${organisationID ? `&organisation_id=${organisationID}` : ''}`,
    method: 'GET'
  });
}

const getProgressFormBatch = (webformIdArray) => {
  const list = webformIdArray.join(',');
  return http({
    url: `/api/v1/webform/${list}/settings?_format=json`,
    method: 'GET'
  });
}

const getRemarksField = (webformId, indicator) => {
  return http({
    url: `/api/v1/webform/${webformId}/field/${indicator}/remarks?_format=json`,
    method: 'GET'
  });
}

const deleteRemarksField = (webformId, indicator, id) => {
  return http({
    url: `/api/v1/webform/${webformId}/field/${indicator}/remark?_format=json&id=${id}`,
    method: "DELETE"
  });
};

const sendRemarksField = (webformId, indicator, data) => {
  return http({
    url: `/api/v1/webform/${webformId}/field/${indicator}/remarks?_format=json`,
    method: 'POST',
    data
  });
}

const getSubmissionPeriods = params => {
  return axios.get(`${params.url}/submissions/periods?form=${params.data}&site_id=${params.site_id}&is_full=${params.is_full ? 1 : 0}`);
}

const getHistoricalValues = params => {
  const { start, end, indicator, siteId, organisation, url } = params;

  return axios.post(`${url}/indicators/history`, {
    site_id: siteId,
    start,
    end,
    organisation,
    indicator
  });
}

const assignUsersToQuestion = (webformId, elementId, data) => {
  return http({
    url: `api/v1/webform/${webformId}/element/${elementId}/assign `,
    method: 'POST',
    data
  });
}

const getWebform = (webformId) => {
  return http({
    method: 'GET',
    url: `api/v1/webform/${webformId}`
  });
}

export {
  assignUsersToQuestion,
  deleteRemarksField,
  getAllCollapseElements,
  getAllElements,
  getElements,
  getForms,
  getHistoricalValues,
  getProgressForm,
  getProgressFormBatch,
  getRemarksField,
  getSegmentationElements,
  getSubmission,
  getSubmissionPeriods,
  getSubmissions,
  getWebform,
  sendRemarksField,
  submitForm
}
