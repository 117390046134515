import React, {useEffect, useState, useMemo} from 'react';
import { useParams } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {Breadcrumb, Spin} from "antd";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";
import { Modal, Tabs, Button } from "antd";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";

import './FilePage.scss';
import history from "../../../../history";
import {Header, TableBlock} from "../../components";
import {convertToBase64} from "../../../../utils";

const FilePage = ({
 t,
 user,
 fileData,
 addFilesForFileManager,
 isFetching,
 getFolderFiles,
 getCategories
}) => {
  const [visible, setVisible] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [uploadFiles, setUploadFiles] = useState({});
  const [searchActive, setSearchActive] = useState(false);
  const [taxonomyDescription, setTaxonomyDescription] = useState('');
  const [benchmarkCategories, setBenchmarkCategories] = useState(null);

  useEffect(() => {

    console.log(user?.organisation);
    if (user?.organisation) {
      getBenchmarkCategories();
      getFolderFiles({folderId: user?.organisation});
    }
  }, [])

  const { TabPane } = Tabs;

  const TabbedModal = ({ isOpen, onClose }) => (
    <Modal title="File upload" open={isOpen} onCancel={onClose} footer={null} getContainer={false} width={700}>
      <Tabs defaultActiveKey={benchmarkCategories?.[0]?.name}>
      {benchmarkCategories?.map((category, index) => (
        <TabPane tab={category.name} key={category.name}>
          <form key={index} onSubmit={sendFile}>
          {(!category.children || category.children.length === 0) && (
            <>
              <div className='category__header'>
                {category.name}
              </div>
              <div className='import__file'>
                <div className='custom__input'>
                  <UploadOutlined /> {t('choose_file')} - {category.name}
                </div>
                <input
                  type="file"
                  onChange={(e) => addFiles(category, e)}
                  multiple={true}
                  name={t('add_file')}
                  placeholder={t('add_file')}
                />
                <div className='category__files'>
                  {setFileNames[category.name]}
                </div>
              </div>
            </>
            )}
           {category.children?.map((child) => (
              <div key={child.name} className="child-category">
                <div className="category__header">{child.name}</div>
                <div className="import__file">
                  <div className="custom__input">
                    <UploadOutlined /> {t("choose_file")} - {child.name}
                  </div>
                  <input
                    type="file"
                    onChange={(e) => addFiles(child, e)}
                    multiple
                    name={t("add_file")}
                    placeholder={t("add_file")}
                    />
                    <div className="category__files">
                    {setFileNames[child.name]}
                  </div>
                </div>
              </div>
            ))}
            <Button className='submit-button' disabled={
                (!uploadFiles[category.name]?.length) && 
                (!category.children || category.children.every(child => !uploadFiles[child.name]?.length))
              } htmlType='submit'>
              {t('export_button')}
            </Button>
            <div className='modal__description'
              dangerouslySetInnerHTML={{ __html: category.description }} />
          </form>
        </TabPane>
        ))}
      </Tabs>
    </Modal>
  );

  const getBenchmarkCategories = async () => {
    if(user?.organisation) {
      let result = await getCategories({id: user?.organisation, roles: user?.roles});

      setBenchmarkCategories(result.categories);
      setTaxonomyDescription(result.taxonomy_description);
    }
  }

  const handlerSearch = (data) => {
    if (!!data) {
      let newSearchData = [];
  
      // Search across all categories
      Object.values(uploadFiles).forEach((fileList) => {
        const filteredFiles = Array.from(fileList).filter(item =>
          item.name.toLowerCase().includes(data.toLowerCase())
        );
        newSearchData = [...newSearchData, ...filteredFiles];
      });
  
      setSearchData(newSearchData);
      setSearchActive(true);
    } else {
      setSearchActive(false);
    }
  };

  const addFiles = (category, event) => {
    setUploadFiles((prev) => ({
      ...prev,
      [category.name]: event.target.files,
    }));
  };
  

  const sendFile = async (e) => {
    e.preventDefault();

    for (const [category, fileList] of Object.entries(uploadFiles)) {
      await fileUpload(fileList, category);
    }
  };

  const fileUpload = async (fileList, category) => {
  
    let filesConvertData = [];
  
    for (const file of fileList) {
      const convertedFile = await convertToBase64(file);

      filesConvertData.push({
        category: category,
        name: file.name,
        file: convertedFile,
      });
    }
  
    return await addFilesForFileManager({
      files: filesConvertData,
      folderId: user?.organisation,
      author: user?.data?.display_name,
    }).finally(() => getFolderFiles({ folderId: user?.organisation }));
  };
  
  
  const setFileNames = useMemo(() => {
  
    const fileNames = {};
  
    Object.entries(uploadFiles).forEach(([category, fileList]) => {
      if (fileList.length > 1) {
        fileNames[category] = <p className={'upload__name'}>{fileList.length} files</p>;
      } else if (fileList.length === 1) {
        fileNames[category] = <p className={'upload__name'}>{fileList[0]?.name}</p>;
      } else {
        fileNames[category] = '';
      }
    });
  
    return fileNames;
  }, [uploadFiles]);

  return (
    isFetching
    ? <Spin
        style={{
          paddingTop: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        size={'large'}
      />
    : (
      <div className={'organization-page'}>

        <div className={'files__header'}>

          <Breadcrumb separator=">">
            <Breadcrumb.Item onClick={() => history.push('/file-manager')}>{t('organization')}</Breadcrumb.Item>
            <Breadcrumb.Item>{user?.organisations?.find((item) => item.id === user?.organisation).title}</Breadcrumb.Item>
          </Breadcrumb>

          <div className={'rules__block'} onClick={() => history.push('/file-manager/rules')}>
            <InfoCircleOutlined />
          </div>
        </div>

        <Header handlerSearch={handlerSearch} />

        <div className='taxonomy__description'>
          <div className='taxonomy__header'>
            <b>Omschrijving</b>
          </div>
          <div className='taxonomy__content'
            dangerouslySetInnerHTML={{ __html: taxonomyDescription }} />
        </div>

        <div className='upload__button'>
          <button onClick={() => setVisible(true)} disabled={!benchmarkCategories}>Upload bestanden</button>
          <TabbedModal isOpen={visible} onClose={() => setVisible(false)} />
        </div>

        <TableBlock
          folder={false}
          fileData={searchActive ? searchData : fileData} />
      </div>
    )
  )
};

export default withTranslation('file_manager')(FilePage);
