import React, {useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {Button, Input, Select } from "antd";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";

import './Header.scss';
import {convertToBase64} from "../../../../utils";

const { Search } = Input;
const { Option } = Select;

const Header = ({
 t,
 showUploadButton = true,
 addFilesForFileManager,
 user,
 handlerSearch,
 getFolderFiles,
 downloadAllFiles,
 getCategories
}) => {

  const [fileData, setFileData] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [benchmarkCategories, setBenchmarkCategories] = useState(null);

  const { id } = useParams();

  const onSearch = (value) => {
    handlerSearch(value);
  }

  const addFiles = (e) => {
    setFileData(e.target.files);
  }

  // const getBenchmarkCategories = async () => {
  //   if(orgId) {
  //     let result = await getCategories(orgId);
  
  //     console.log('Got a result. Setting benchmark categories: ', result);
  //     setBenchmarkCategories(result);
  //   }
  // }

  const fileUpload = async (file) => {
    let filesConvertData = [];
    for (let i = 0; i < file.length; i++) {
      const convertedFile = await convertToBase64(file[i])
      const fileInfo = {
        name: file[i]?.name,
        file: convertedFile
      };
      filesConvertData.push(fileInfo);
    }

    return await addFilesForFileManager({
      files: filesConvertData,
      folderId: orgId ? orgId : id,
      author: user?.data?.display_name,
    }).finally(() => getFolderFiles({ folderId: id }));
  }

  const handlerSelectOrg = (value) => {
    setOrgId(value);
    //getBenchmarkCategories();
  }

  const sendFile = async (e) => {
    e.preventDefault();
    await fileUpload(fileData);
  }

  const setFileNames = useMemo(() => {
    if (fileData?.length > 1) {
      return <p className={'upload__name'}>{fileData?.length} files</p>
    } else if (fileData?.length === 1) {
      return <p className={'upload__name'}>{fileData[0]?.name}</p>
    } else {
      return ''
    }
  }, [fileData]);

  const handlerDownloadAllFiles = () => {
    downloadAllFiles({ folderId: id })
  }

  return (
    <header className={'file-manager__header'}>
      <Search
        placeholder={t('input_search')}
        className={'search__block'}
        style={{ marginLeft: 'auto' }}
        onSearch={onSearch}
        allowClear={true}
        enterButton
      />
    </header>
  )
};

export default withTranslation('file_manager')(Header) ;