import "./SegmentationFilter.scss";

import {
  CheckboxSegmentationFilter,
  CheckboxesSegmentationFilter,
  NumberSegmentationFilter,
  SelectSegmentationFilter,
  TextSegmentationFilter,
} from "./Types";
import React from "react";
import { withTranslation } from "react-i18next";

function SegmentationFilter(props) {
  const { filterChanged, filterCleared, filterState, item, popoverId, t } = props;

  return (
    <div className={"segmentation-filter-wrapper"}>
      <div className={"segmentation-filter-header"}>
        <span className={"segmentationfilters-filter-title"}>{item.title}</span>
        <div className={"segmentationfilters-filter-subheader"}>
          {(!!filterState && (filterState.checked != null || filterState.max != null || filterState.min != null || filterState.search != '' || filterState.searchValues.length > 0)) && (
            <span className={"segmentationfilters-filter-clear"} onClick={() => filterCleared(item)}>{t(`filter_clear`)}</span>
          )}
          <span className={"segmentationfilters-filter-type"}>{t(`filter_type_${item.type}`)}</span>
        </div>
      </div>

      <div className={"segmentation-input-wrapper"}>
        {item.type == "checkbox" && (
          <CheckboxSegmentationFilter filterChanged={filterChanged} filterState={filterState} item={item} popoverId={popoverId}/>
        )}
        {item.type == "checkboxes" && (
          <CheckboxesSegmentationFilter filterChanged={filterChanged} filterState={filterState} item={item} popoverId={popoverId} />
        )}
        {item.type == "number" && (
          <NumberSegmentationFilter filterChanged={filterChanged} filterState={filterState} item={item} />
        )}
        {item.type == "select" && (
          <SelectSegmentationFilter filterChanged={filterChanged} filterState={filterState} item={item} popoverId={popoverId} />
        )}
        {(item.type == "textfield" || item.type == "textarea") && (
          <TextSegmentationFilter filterChanged={filterChanged} filterState={filterState} item={item} popoverId={popoverId} />
        )}
      </div>
    </div>
  );
}

export default withTranslation("benchmark")(SegmentationFilter);