import axios from 'axios';
import http from './http';

const getFilteredOrganizationsForReport = (params) => {
  const { filters, id, site_id, url } = params;
  return axios.post(`${url}/reports/${id}/organizations?site_id=${site_id}`, filters);
}

const getFullReports = () => {
  return http({
    url: '/api/v1/report/full-list?_format=json',
    method: 'GET'
  })
}

const getReportById = reportId => {
  return http({
    url: `/api/v1/report/${reportId}?_format=json`,
    method: 'GET'
  })
}

const getReportPeriods = (params) => {
  const { id, organization, site_id, url } = params;
  return axios.get(`${url}/reports/${id}/periods?site_id=${site_id}&organization=${organization}`);
}

const getReports = () => {
  return http({
    url: '/api/v1/report/list?_format=json',
    method: 'GET'
  })
}

const getReportsByWebformId = webformId => {
  return http({
    url: `/api/v1/webform/${webformId}/reports`,
    method: 'GET'
  })
}

export {
  getFilteredOrganizationsForReport,
  getFullReports,
  getReportById,
  getReportPeriods,
  getReports,
  getReportsByWebformId
}
