import { connect } from "react-redux";

import actions from '../../../../store/actions';
import FilePage from "./FilePage";


const mapStateToProps = (state) => ({
  user: state.user.data,
  fileData: state.fileManager.fileData,
  isFetching: state.fileManager.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  addFilesForFileManager: (params) => dispatch(actions.fileManager.addFilesForFileManager(params)),
  getFolderFiles: (params) => dispatch(actions.fileManager.getFolderFiles(params)),
  getCategories: (params) => dispatch(actions.categories.getBenchmarkCategories(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilePage);

