import "./ItemDropdown.scss";

import React from "react";
import { Select, Spin } from "antd";
import { withTranslation } from "react-i18next";

const { Option } = Select;

function ItemDropdown(props) {
  const {
    activeItem,
    availableItems,
    itemChanged,
    label,
    loadingItems
  } = props;

  return (
    <div className={"item-wrapper"}>
      <p className={"item-label"}>{label}</p>

      {loadingItems ? (<Spin/>) : (
        <Select
          className={"ant-select-borderless"}
          onChange={itemChanged}
          value={activeItem}
        >
          {availableItems.map(item => (
            <Option key={item.name} value={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </div>
  );
}

export default withTranslation("benchmark")(ItemDropdown);