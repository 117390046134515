import CheckboxSegmentationFilter from "./CheckboxSegmentationFilter";
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch) => ({
  
})

const mapStateToProps = (state) => ({

});

export default connect(
  mapDispatchToProps,
  mapStateToProps
)(CheckboxSegmentationFilter);