import React, {useEffect, useState} from 'react';
import moment from "moment";
import {useParams} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import { Table, Modal } from "antd";
import FolderOutlined from "@ant-design/icons/lib/icons/FolderOutlined";
import FileTextOutlined from "@ant-design/icons/lib/icons/FileTextOutlined";

import history from "../../../../history";
import './TableBlock.scss';
import ModalEdit from "../ModalEdit";

const TableBlock = ({
  t,
  folder,
  folderData,
  fileData,
}) => {

  const { id } = useParams();

  const [dataFolder, setDataFolder] = useState([]);
  const [dataFiles, setDataFiles] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  const columnsFolder = [
    {
      title: t('organization'),
      dataIndex: 'organization',
      key: 'organization',
      width: '70%',
      sorter: (a, b) => a.organization.localeCompare(b.organization),
      render: text => (
        <div className={'folder__block'}>
          <FolderOutlined />
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: t('upload_date'),
      dataIndex: 'last_update',
      key: 'last_update',
      render: text => <p>{text}</p>,
    },
  ];

  const columnsFiles = [
    {
      title: t('filename'),
      dataIndex: 'filename',
      key: 'filename',
      sorter: (a, b) => a.filename.localeCompare(b.filename),
      render: text => (
        <div className={'folder__block'}>
          <FileTextOutlined />
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category',
      render: text => <p>{text}</p>,
    },
    {
      title: t('upload_date'),
      dataIndex: 'last_update',
      key: 'last_update',
      render: text => <p>{text}</p>,
    },
    {
      title: t('author'),
      dataIndex: 'author',
      key: 'author',
      render: text => <p>{text}</p>,
    },
    // {
    //   title: t('action'),
    //   dataIndex: 'action',
    //   key: 'action',
    //   render: (text, record) => (
    //     <div className={'action__block'}>
    //       {
    //         user?.scopes?.find(i => i === 'file_download') &&
    //         <DownloadOutlined
    //           style={{ fontSize: '20px' }}
    //           onClick={() => handlerDownloadFile(record)}
    //         />
    //       }

    //       {
    //         user?.scopes?.find(i => i === 'file_edit') &&
    //         <EditOutlined
    //           style={{ fontSize: '20px' }}
    //           onClick={() => handlerEditFile(record)}
    //         />
    //       }

    //       {
    //         user?.scopes?.find(i => i === 'file_delete') &&
    //         <DeleteOutlined
    //           style={{ fontSize: '20px' }}
    //           onClick={() => handlerDeleteFile(record)}
    //         />
    //       }
    //     </div>
    //   )
    // },
  ];

  const closeModal = () => setModalVisible(false);

  // const handlerDownloadFile = (record) => {
  //   downloadFiles({
  //     folderId: id,
  //     fileId: record.key,
  //     fileName: record.filename,
  //   });
  // };

  // const handlerEditFile = (record) => {
  //   setModalData(record);
  //   setModalVisible(true);
  // }


  // const handlerDeleteFile = (record) => {
  //   deleteFiles({
  //     folderId: id,
  //     fileId: record.key
  //   }).finally(() => getFolderFiles({ folderId: id }));
  // }

  useEffect(() => {
    if (folder && folderData?.length) {
      const dataFolderColumn = folderData?.map(item => {
        return {
          key: item?.id,
          organization: item?.name,
          last_update:
            item?.updated_at
              ? moment(`${item?.updated_at}`).format('YYYY-MM-DD HH:mm:ss')
              : moment(`${item?.created_at}`).format('YYYY-MM-DD HH:mm:ss'),
        };
      })

      setDataFolder(dataFolderColumn);
    } else if (fileData?.length) {
      const dataFileColumn = fileData?.map(item => {
        console.log(item);
        return {
          key: item?.id,
          filename: item?.name,
          category: item?.category,
          last_update: moment(`${item?.updated_at}`).format('YYYY-MM-DD HH:mm:ss'),
          author: item?.author,
        };
      })

      setDataFiles(dataFileColumn);
    }
  }, [folderData, fileData]);


  const handlerOpenFolder = (record) => {
    history.push(`/file-manager/${record.organization}/${record.key}`);
  }

  const checkPagination = () => {
    if (dataFolder?.length > 12 || dataFiles?.length > 12) {
      return { defaultPageSize: 12, defaultCurrent: 1, showSizeChanger: false };
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal
        footer={null}
        width={980}
        visible={modalVisible}
        onCancel={closeModal}
        title={t('edit_file')}
      >
        <ModalEdit value={modalData} folderId={id} />
      </Modal>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: () => folder && handlerOpenFolder(record, rowIndex)
          }
        }}
        className={'file-manager__table'}
        columns={ folder ? columnsFolder : columnsFiles}
        dataSource={folder ? dataFolder : dataFiles}
        pagination={checkPagination()}
      />
    </>
  );
};

export default withTranslation('file_manager')(TableBlock);