import { api } from '../../services'

const getFilteredOrganizationsForReport = (id, filters) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    filters,
    id,
    site_id: settings.site_id,
    url: settings.calculation_url
  };

  return api.reports.getFilteredOrganizationsForReport(params);
}

const getReportBlockDocxById = data => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings
  return api.report_blocks.getReportBlockDocxById(data)
}

const getReportPeriods = (id, organization) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    id,
    organization,
    site_id: settings.site_id,
    url: settings.calculation_url
  };

  return api.reports.getReportPeriods(params);
}

const getStaticPageDocxById = data => (dispatch, getFromStore) => {
  return api.report_blocks.getStaticPageDocxById(data)
}


export {
  getFilteredOrganizationsForReport,
  getReportBlockDocxById,
  getReportPeriods,
  getStaticPageDocxById
}