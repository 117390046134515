import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { en, nl } from './resources'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'nl',
    debug: false,
    resources: {
      en,
      nl
    },
    defaultNS: 'general',
    react: {
      wait: true
    },
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
