import http from './http'

const getBenchmarkCategories = (params) => {
  //"/api/v1/benchmark_categories/{oi}",
  const { id, roles } = params;

  const rolesQuery = roles?.map(role => `roles[]=${encodeURIComponent(role)}`).join('&');

  return http({
    url: `/api/v1/benchmark_categories/${id}?_format=json${rolesQuery ? `&${rolesQuery}` : ''}`,
    method: 'GET'
  })
}

export {
  getBenchmarkCategories
};