import { connect } from "react-redux";
import ItemDropdown from "./ItemDropdown";

const mapDispatchToProps = dispatch => ({

});

const mapStateToProps = state => ({

});

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(ItemDropdown);