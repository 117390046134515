import React from "react";
import { Select } from "antd";
import { withTranslation } from "react-i18next";

function CheckboxSegmentationFilter(props) {
  const { filterChanged, filterState, item, popoverId, t } = props;

  const onSelectChanged = (value) => {
    filterChanged({
      ...filterState,
      checked: value
    });
  }

  return (
    <>
      <Select
        allowClear={true}
        getPopupContainer={() => document.getElementById(popoverId)}
        onChange={onSelectChanged}
        placeholder={t("select")}
        value={filterState.checked}
      >
        <Select.Option value={true} key={1}>
          {t('checkbox_enabled')}
        </Select.Option>
        <Select.Option value={false} key={0}>
          {t('checkbox_disabled')}
        </Select.Option>
      </Select>
    </>
  );
}

export default withTranslation("benchmark")(CheckboxSegmentationFilter);