import "./SegmentationFilters.scss";

import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import SegmentationFilter from "./SegmentationFilter";
import { withTranslation } from "react-i18next";

function SegmentationFilters(props) {
  const { id, onActiveFiltersChanged, popoverId, segmentationFilters, t } = props;

  const [activeFilters, setActiveFilters] = useState(segmentationFilters?.elements?.map(sf => 
    { 
      return { 
        checked: null,
        filter: sf.filters.length > 0 ? sf.filters[0] : '', 
        indicator: sf.indicator, 
        max: null, 
        min: null, 
        search: '', 
        searchValues: [], 
        type: sf.type 
      }
    }) ?? []);

  const debouncedOnActiveFiltersChanged = useMemo(() => 
    debounce((filters) => {
      onActiveFiltersChanged(filters);
    }, 500), 
    [onActiveFiltersChanged]);

  const filterChanged = (filter) => {
    const existingFilter = activeFilters.find(f => f.indicator === filter.indicator);
    let filters = [];
    if (existingFilter == null)
      filters = [...activeFilters, filter]; 
    else
      filters = activeFilters.map(f => {
        if (f.indicator === filter.indicator)
          return filter;
  
        return f;
      });

    setActiveFilters(filters);

    debouncedOnActiveFiltersChanged(filters);
  }

  const filterCleared = (filter) => {
    const filters = activeFilters.map(f => {
      if (f.indicator === filter.indicator) {
        f.checked = null;
        f.max = null;
        f.min = null;
        f.search = '';
        f.searchValues = [];
      }

      return f;
    });

    setActiveFilters(filters);
    onActiveFiltersChanged(filters);
  }

  if ((segmentationFilters?.elements?.length ?? 0) == 0) 
    return null;

  return (
    <div className={"segmentationfilters-wrapper"}>
      {segmentationFilters.elements.map((item) => (
        <SegmentationFilter filterChanged={filterChanged} filterCleared={filterCleared} filterState={activeFilters.find(f => f.indicator === item.indicator)} item={item} key={item.indicator} popoverId={popoverId}/>
      ))}
    </div>
  );
}

export default withTranslation("benchmark")(SegmentationFilters);