import "./ReportBlockPage.scss";

import { api } from "../../services";
import { Button, Checkbox, message, Modal, Popover, Select, Spin, Tooltip } from "antd";
import { DownloadOutlined, DownOutlined, EditOutlined } from "@ant-design/icons/lib/icons";
import Iframe from "../../components/Iframe/Iframe";
import { LevelFilter, ModalComparisonGroup, SearchInput, SegmentationFilters } from "../../components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

function ReportBlockPage(props) {
  const { id } = useParams();

  const { 
    app,
    comparisonGroups,
    getBenchmarkComparisonGroups,
    getFilteredOrganizationsForReport,
    getReportPeriods,
    t,
    user
  } = props;

  const [access, setAccess] = useState(true);
  const [activeGroups, setActiveGroups] = useState([]);
  const [activeOrganizations, setActiveOrganizations] = useState([]);
  const [activeSegmentationFilters, setActiveSegmentationFilters] = useState({levelFilters: [], segmentationFilters: []});
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [currentPeriod, setCurrentPeriod] = useState(null);
  const [error, setError] = useState(null);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [levelFilters, setLevelFilters] = useState(null);
  const [linkedForms, setLinkedForms] = useState([]);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [loadingPeriods, setLoadingPeriods] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingReportContent, setLoadingReportContent] = useState(false);
  const [loadingSegmentationFilters, setLoadingSegmentationFilters] = useState(false);
  const [modalGroupEditorVisible, setModalGroupEditorVisible] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsSearchValue, setOrganizationsSearchValue] = useState(null);
  const [periodEnd, setPeriodEnd] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [periodStart, setPeriodStart] = useState(null);
  const [periodType, setPeriodType] = useState('month');
  const [popoverGroupsVisible, setPopoverGroupsVisible] = useState(false);
  const [popoverOrganizationsVisible, setPopoverOrganizationsVisible] = useState(false);
  const [popoverPeriodsVisible, setPopoverPeriodsVisible] = useState(false);
  const [reportHtml, setReportHtml] = useState(null);
  const [segmentationFilterData, setSegmentationFilterData] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);

  // Loads the initial data like the current organization, the comparisongroups and available organizations
  // Only runs once after the initial render of the component by passing an empty dependency array
  useEffect(() => {
    const organization = user?.data?.organisations?.find((item) => item.id === user?.data?.organisation);
    setCurrentOrganization(organization);

    getBenchmarkComparisonGroups();
    loadOrganizations();
  }, []);

  // Reloads the report data whenever the report ID in the URL changes
  useEffect(() => {
    if (!!!id)
      return;

    loadReportData();
  }, [id]);

  // Retrieves the HTML content of the report
  useEffect(() => {
    // Don't load any data when the report ID is missing or the period is empty
    if (!!!id || !!!periodStart || !!!periodEnd)
      return;

    async function fetchData() {
      setLoadingReportContent(true);

      await api.report_blocks.getReportBlockHtmlById(id, periodStart, periodEnd, activeGroups.map(g => g.id), activeOrganizations.map(o => o.id))
        .then((response) => {
          setReportHtml(response.data.replaceAll(window.location.origin, process.env.REACT_APP_BASE_URL)
            .replaceAll(`href="/`, `href="${process.env.REACT_APP_BASE_URL}/`)
            .replaceAll(`src="/`, `src="${process.env.REACT_APP_BASE_URL}/`)
            .replaceAll(`href="./`, `href="${process.env.REACT_APP_BASE_URL}/`)
            .replaceAll(`src="./`, `src="${process.env.REACT_APP_BASE_URL}/`)
          );
        })
        .catch((e) => {
          console.log(e.message);
        })
        .finally(() => setLoadingReportContent(false));
    }
    
    fetchData();
  }, [activeGroups, activeOrganizations, periodStart, periodEnd, id]);

  // Loads the available periods for the report
  // Runs after the report ID or user's organization changes to ensure only periods with existing data are shown for the user's organization
  useEffect(() => {
    if (!!!currentOrganization || !!!id)
      return;

    setLoadingPeriods(true);

    getReportPeriods(id, currentOrganization.id)
      .then(async (response) => {
        const { data, status } = await response;
        await data;

        if (!!data?.length && status === 200) {
          setPeriods(data);
          setCurrentPeriod(data[0].name);
          setPeriodStart(moment.parseZone(data[0].condition?.period_start).format('YYYY-MM-DD HH:mm:ss'));
          setPeriodEnd(moment.parseZone(data[0].condition?.period_end).format('YYYY-MM-DD HH:mm:ss'));
          setPeriodType(data[0].condition?.period_name);
        }
        else if (status === 200) {
          setAccess(false);
        }
      })
      .catch((e) => {
        console.error(e);
        message.error(t('error_loading_periods'));
        setAccess(false);
      })
      .finally(() => setLoadingPeriods(false));

  }, [currentOrganization, id]);

  const loadOrganizations = async () => {
    setLoadingOrganizations(true);

    await getFilteredOrganizationsForReport(id, activeSegmentationFilters)
      .then((response) => {
        setOrganizations(response.data);
        setFilteredOrganizations(response.data);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingOrganizations(false));
  }

  const loadReportData = () => {
    setLoadingReport(true);

    api.report_blocks.getReportBlockById(id, false, currentOrganization)
      .then(async data => {
        if (!!!data)
          throw new Error(data.error);
        
        setLinkedForms(data["node"].linked_webforms);
      })
      .catch((error) => {
        setError(error?.response?.data.errors[0]);
      })
      .finally(() => setLoadingReport(false));
  }

  const loadSegmentationFilters = () => {
    setLoadingSegmentationFilters(true);

    api.questionnaires
      .getSegmentationElements(id)
      .then((response) => {
        const { status, data } = response;
        if (status === 200)
          setSegmentationFilterData(data.webform);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingSegmentationFilters(false));
  }

  // Retrieves the filtered and sorted organizations for selection
  const getFilteredOrganizations = () => {
    let orgs = filteredOrganizations
      .filter(x => x.id != currentOrganization.id) // Skip own organisation
      .sort((x, y) => x.title.localeCompare(y.title)); // Sort by title

    if(organizationsSearchValue)
      orgs = orgs?.filter((item) => item.title.search(new RegExp(organizationsSearchValue, 'i')) !== -1);

    return orgs;
  };

  const handleAddGroupClick = () => {
    setModalGroupEditorVisible(true);
    setPopoverGroupsVisible(false);
  };

  const handleApplyGroupsClick = () => {
    setPopoverGroupsVisible(false);
    setActiveGroups(selectedGroups);

    message.success(`Filter ${t('apply')}`);
  };

  const handleApplyOrganizationsClick = () => {
    setPopoverOrganizationsVisible(false);
    setActiveOrganizations(selectedOrganizations);

    message.success(`Filter ${t("apply")}`);
  };

  const handleCloseGroupEditor = () => {
    setModalGroupEditorVisible(false);
    setSelectedGroup(null);
  };

  const handleFrameLoaded = () => {
    var links = document.querySelector("iframe").contentDocument.querySelectorAll("html link");
    var scripts = document.querySelector("iframe").contentDocument.querySelectorAll("html script");
    var imgs = document.querySelector("iframe").contentDocument.querySelectorAll("html img");
    
    links.forEach(element => {
      if (element.href.startsWith(window.location.origin))
        element.href = element.href.replace(window.location.origin, process.env.REACT_APP_BASE_URL);
    });

    scripts.forEach(element => {
      if (element.src.startsWith(window.location.origin))
        element.src = element.src.replace(window.location.origin, process.env.REACT_APP_BASE_URL);
    });

    imgs.forEach(element => {
      if (element.src.startsWith(window.location.origin))
        element.src = element.src.replace(window.location.origin, process.env.REACT_APP_BASE_URL);
    });
  };

  const handleDeleteGroupClick = (comparisonGroup) => {
    setLoadingGroups(true);

    api.benchmark.deleteComparisonGroup(comparisonGroup.id)
      .then((response) => {
        const { status } = response;
        if (status < 300)
          message.success(t("delete_comparison_group"));
      })
      .finally(() => {
        getBenchmarkComparisonGroups();
        setSelectedGroups(selectedGroups.filter((item) => item.id != comparisonGroup.id));
        setPopoverGroupsVisible(false);
        setLoadingGroups(false);
      });
  }

  const handleDownloadDocx = async () => {
    await api.report_blocks.getReportBlockDocxById(id, periodStart, periodEnd, activeGroups.map(g => g.id), activeOrganizations.map(o => o.id))
      .then((response) => {
        const { status, data } = response;
        if (status < 200 || status >= 300) return;

        const link = document.createElement("a");
        link.target = "_blank";
        link.href = data?.file_url;
        link.setAttribute("download", `${data?.filename}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((e) => message.error(e.message));
  };

  const handleEditGroupClick = (group) => {
    setModalGroupEditorVisible(true);
    setPopoverGroupsVisible(false);
    setSelectedGroup(group);
  };

  const handleGroupsChange = (ids) => {
    setSelectedGroups(comparisonGroups.filter((item) => ids.includes(item.id)));
  };

  async function handleLevelFiltersChange(levelFilters) {
    setLoadingOrganizations(true);

    const updatedFilters = {
      ...activeSegmentationFilters,
      levelFilters: levelFilters
    };

    setActiveSegmentationFilters(updatedFilters);

    await getFilteredOrganizationsForReport(id, updatedFilters)
        .then((response) => {
          setFilteredOrganizations(response.data);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingOrganizations(false));
  };

  const handleOrganizationsChange = (ids) => {
    setSelectedOrganizations(organizations.filter((item) => ids.includes(item.id)));
  }

  const handlePeriodClick = (period) => {
    setCurrentPeriod(period);
    setPopoverPeriodsVisible(false);
    
    const currentPeriod = periods.find((item) => item.name === period);
    if (currentPeriod) {
      setPeriodEnd(moment.parseZone(currentPeriod?.condition?.period_end).format('YYYY-MM-DD HH:mm:ss'));
      setPeriodStart(moment.parseZone(currentPeriod?.condition?.period_start).format('YYYY-MM-DD HH:mm:ss'));
      setPeriodType(currentPeriod?.condition?.period_name);
    }
  };

  async function handleSegmentationFiltersChange(segmentationFilters) {
    setLoadingOrganizations(true);

    const updatedFilters = {
      ...activeSegmentationFilters,
      segmentationFilters: segmentationFilters
    };

    setActiveSegmentationFilters(updatedFilters);

    await getFilteredOrganizationsForReport(id, updatedFilters)
        .then((response) => {
          setFilteredOrganizations(response.data);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingOrganizations(false));
  };

  const contentPopoverGroups = (
    <div className={'report-header-popover-content'}>
      <div className={'wrapper'}>

        <div className={'column'}>
          <p className={'title'}>{t("saved_comparison_groups")}</p>
          {loadingGroups ? (<Spin/>) : (
            <div className={'checkboxes'}>
              <Checkbox.Group onChange={handleGroupsChange} value={selectedGroups.map(g => g.id)}>
                {comparisonGroups?.sort((x, y) => x.name.localeCompare(y.name))?.map((item) => (
                  <div className={'checkbox'} key={item.id}>
                    <Checkbox key={item.id} value={item.id}>{item.name}</Checkbox>
                    <div className={'actions'}>
                      {item.personal_group && (
                        <>
                          <EditOutlined onClick={() => handleEditGroupClick(item)}/>
                          <div className={'delete'} onClick={() => handleDeleteGroupClick(item)}/>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </Checkbox.Group>
            </div>
          )}
        </div>

        <div className={'column'}>
          <p className={'title'}>{t("create_comparison_group")}</p>
          {loadingGroups ? (<Spin/>) : (
            <div className={'button-actions'}>
              <Button className={'action'} onClick={handleAddGroupClick}>
                {t('add_new_group')}
              </Button>

              <Button className={'action apply'} disabled={loadingReport} onClick={handleApplyGroupsClick}>
                {t('apply')} {loadingReport && (<span style={{marginLeft: '1em'}}><Spin size={'small'}/></span>)}
              </Button>
            </div>
          )}
        </div>

      </div>
    </div>
  );

  const contentPopoverOrganizations = (
    <div className={'report-header-popover-content'}>
      <div className={'wrapper'}>

        <div className={'column'}>
          <p className={'title'}>{t("individual_organizations_title")}</p>
          <p className={'subtitle'}>{t("individual_organizations_subtitle")}</p>
          {loadingOrganizations ? (<Spin/>) : (
            <div className={'checkboxes'}>
              <Checkbox.Group onChange={handleOrganizationsChange} value={selectedOrganizations.map(o => o.id)}>
                {getFilteredOrganizations()?.map((item) => (
                  <Tooltip key={item.id} placement="left" title={item.title} zIndex={20}>
                    <Checkbox key={item.id} value={item.id}>{item.title}</Checkbox>
                  </Tooltip>
                ))}
              </Checkbox.Group>
            </div>
          )}
          <Button onClick={handleApplyOrganizationsClick}>{t('apply')}</Button>
        </div>

        <div className={'column'}>
          <p className={'title'}>{t('filters')}</p>
          <div>
            <p>
              <SearchInput
                onSearch={setOrganizationsSearchValue}
                realtime={true}
                text={t('search_in_organizations')}
                value={organizationsSearchValue}
              />
            </p>

            <LevelFilter levels={levelFilters} onLevelFiltersChanged={handleLevelFiltersChange} popoverId={'individual_organizations_popover'} />
            <SegmentationFilters onActiveFiltersChanged={handleSegmentationFiltersChange} popoverId={'individual_organizations_popover'} segmentationFilters={segmentationFilterData}/>
          </div>
        </div>

      </div>
    </div>
  );

  const contentPopoverPeriods = (
    <div className={'report-header-popover-content'}>
      <div className={'wrapper'}>

        <div className={'column single'}>
          <p className={'title'}>{t("available_periods")}</p>
          {loadingPeriods ? (<Spin/>) : (
            <div className={'list'}>
              {periods.map(p => (
                <div className={'list-item'} key={p.name} onClick={() => handlePeriodClick(p.name)}>
                  <p className={'list-item-title'}>{p.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>

      </div>
    </div>
  );

  // When the user/organization can't access the report yet, display a permission error
  if (!!!access)
    return (
      <p>{t('no_permissions')}</p>
    );

  return (
    <div className={'report-page-wrapper'}>
      <Modal
        footer={null}
        onCancel={handleCloseGroupEditor}
        open={modalGroupEditorVisible}
        style={{ maxWidth: "100vw", top: 0 }}
        title={selectedGroup === null ? t("create_new_group") : `${t("edit_group")} ${selectedGroup.name}`}
        width={"100%"}
      >
        <ModalComparisonGroup
          activeOrganization={currentOrganization}
          closeModal={handleCloseGroupEditor}
          comparisonGroupEditId={selectedGroup === null ? null : selectedGroup.id}
          indicatorFilterData={segmentationFilterData}
          modalEdit={selectedGroup === null ? null : selectedGroup.name}
          organizationsInEditGroup={selectedGroup === null ? null : selectedGroup.organizations}
          visible={modalGroupEditorVisible}
        />
      </Modal>

      {!user.isGuest && !app.settings.hide_comparison_group_select && (
        <div className={'report-page-header'}>
          <div className={'report-page-header-group'}>
            <div className={'report-page-header-item'}>
              <Button 
                color="blue" 
                icon={<DownloadOutlined />} 
                onClick={handleDownloadDocx} 
                size="sm" 
                style={{ padding: "11px 12px" }}
              >
                {t("export_to_docx")}
              </Button>
            </div>
          </div>

          <div className={'report-page-header-group'}>
            <div className={'report-page-header-item'}>
              <Popover 
                content={contentPopoverPeriods}
                id={"periods_popover"} 
                onOpenChange={setPopoverPeriodsVisible}
                open={popoverPeriodsVisible} 
                placement="bottomRight" 
                trigger={"click"}
              >
                <div className={'report-header-popover'}>
                  <p>{currentPeriod || t("select_period")}</p>
                  <DownOutlined/>
                </div>
              </Popover>
            </div>

            <div className={'report-page-header-item'}>
              <Popover 
                content={contentPopoverGroups}
                id={"comparison_groups_popover"}
                onOpenChange={setPopoverGroupsVisible}
                open={popoverGroupsVisible} 
                placement="bottomRight" 
                trigger={"click"}
              >
                <div className={'report-header-popover'}>
                  <p>{t("comparison_groups")} {selectedGroups.length > 0 ? `(${selectedGroups.length})` : null}</p>
                  <DownOutlined/>
                </div>
              </Popover>
            </div>

            <div className={'report-page-header-item'}>
              <Popover 
                content={contentPopoverOrganizations}
                id={"individual_organizations_popover"}
                onOpenChange={setPopoverOrganizationsVisible} 
                open={popoverOrganizationsVisible} 
                placement="bottomRight" 
                trigger={"click"}
              >
                <div className={'report-header-popover'}>
                  <p style={{ maxWidth: "300px" }}>{t("select_individual_organizations")} {selectedOrganizations.length > 0 ? `(${selectedOrganizations.length})` : null}</p>
                  <DownOutlined />
                </div>
              </Popover>
            </div>
          </div>
        </div>
      )}

      {(loadingReport || loadingReportContent || !!!reportHtml) && (
        <div className="d-flex justify-content-center mt-5">
          <Spin />
        </div>
      )}

      {!loadingReport && !!!error && !!reportHtml && (
        <div style={{ width: "100%", height: "100%", padding: "15px 0" }}>
          <Iframe frameLoaded={handleFrameLoaded} iFrameReady={!!!loadingReportContent} srcDoc={reportHtml}/>
        </div>
      )}

      {!loadingReport && !!error && (
        <div>{error}</div>
      )}
    </div>
  );
}

export default withTranslation("reports")(ReportBlockPage);
