import "./ModalBenchmarkChart.scss";
import "../../../../utils/highchartsExtensions.js";

import { DatePicker, message, Select, Spin } from "antd";
import dayjs from "dayjs";
import Highcharts, { setOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

const { Option } = Select;
const { RangePicker } = DatePicker;

const chartTypes = ['area', 'bar', 'column', 'line', 'scatter']; // Type "pie" can be added, but requires a different config pattern to be sent to Highcharts
const periodTypes = ['week', 'month', 'quarter', 'half', 'year'];

function ModalBenchmarkChart(props) {
  const {
    columns = [],
    element,
    getBenchmarkChartData,
    groups = [],
    organization,
    organizations = [],
    periodEnd = null,
    periodStart = null,
    periodType = 'month',
    settingsBenchmark,
    t,
    value,
    visible = false
  } = props;

  const [activeChartType, setActiveChartType] = useState(settingsBenchmark?.charts?.show_line_charts === 'yes' ? chartTypes[3] : chartTypes[2]);
  const [chartOptions, setChartOptions] = useState(null);
  const [chartPeriodEnd, setChartPeriodEnd] = useState(null);
  const [chartPeriodStart, setChartPeriodStart] = useState(null);
  const [loadingChart, setLoadingChart] = useState(false);

  // Sets the default start and end period using the selected period in the benchmark.
  // Will modify the start date based on the period type, so it displays the last 4 periods in the chart.
  useEffect(() => {
    if (!!!periodType || !!!periodStart || !!!periodEnd)
      return;

    if (!!chartPeriodStart && !!chartPeriodEnd)
      return;

    let dayJsStart = dayjs(periodStart);
    let dayJsEnd = dayjs(periodEnd);

    switch (periodType) {
      case 'half':
        dayJsStart = dayJsStart.subtract(2, 'year');
        dayJsStart = dayJsStart.month() > 6 ? dayJsStart.startOf('year').add(6, 'month') : dayJsStart.startOf('year');
        dayJsEnd = dayJsEnd.month() > 6 ? dayJsEnd.endOf('year') : dayJsEnd.endOf('year').subtract(6, 'month');
        break;
      default:
        dayJsStart = dayJsStart.subtract(4, periodType);
        dayJsStart = dayJsStart.startOf(periodType);
        dayJsEnd = dayJsEnd.endOf(periodType);
        break;
    }

    setChartPeriodEnd(dayJsEnd);
    setChartPeriodStart(dayJsStart);
  }, [periodStart, periodEnd, periodType]);

  useEffect(() => {
    if (!!!visible || loadingChart || !!!element || !!!organization || !!!chartPeriodStart || !!!chartPeriodEnd)
      return;

    setLoadingChart(true);

    getBenchmarkChartData({
      batch: true,
      calculation_types: columns,
      group_by: 'time_period',
      groups: groups.map(g => g.id),
      indicator: element.webform_indicator,
      organizations: organizations.map(o => o.id),
      period_start: chartPeriodStart.format("YYYY-MM-DD"),
      period_end: chartPeriodEnd.format("YYYY-MM-DD"),
      period_type: periodType,
      organization: organization?.id
    })
      .then((response) => {
        const { ...data } = response.data.values;

        const updatedChartOptions = {
          chart: {
            type: activeChartType
          },
          exporting: {
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true
                  }
                }
              }
            },
            fallbackToExportServer: false,
            scale: 3
          }, 
          plotOptions: {
            column: {
              borderWidth: 1,
              pointPadding: 0.1,
            }
          },
          series: data && data.series?.map((item, index) => {
            return {
              color: settingsBenchmark?.charts?.[`chart_color_${index + 1}`], 
              data: item.results.map(r => r.indicator_value),
              name: item.id.startsWith('aggregation') ? t(item.name) : item.name
            }
          }),
          subtitle: {
            text: element.webform_indicator
          },
          title: {
            text: element.title
          },
          tooltip: {
            footerFormat: '</table>',
            headerFormat: '<table><tr><th colspan="2" style="border-bottom: 1px solid #DDD;">{point.key}</th></tr>',
            pointFormat: '<tr><td style="color: {series.color}; padding: 0 1rem 0 0;">{series.name}:</td><td style="padding: 0; text-align: right;"><b>{point.y}</b></td></tr>',
            shared: true,
            useHTML: true
          },
          xAxis: {
            categories: data.periods,
            title: {
              text: t('periods')
            }
          },
          yAxis: {
            labels: {
              formatter: function () {
                return !!element.options?.length ? element.options.find(opt => opt.key === this.value)?.value || "" : this.value;
              }
            },
            lineWidth: 1,
            minorGridLineWidth: 0,
            minorTickInterval: 'auto',
            minorTicks: settingsBenchmark?.charts?.show_minor_ticks_lines === 'yes',
            minorTickWidth: 3,
            title: {
              text: t('values')
            }
          },
        };

        setChartOptions(updatedChartOptions);
      })
      .catch((e) => {
        console.error(e);
        message.error(t('error_loading_chart'));
      })
      .finally(() => setLoadingChart(false));

  }, [activeChartType, chartPeriodEnd, chartPeriodStart, columns, element, groups, organizations, settingsBenchmark, visible]);

  const handleChangeActiveType = (activeType) => {
    setActiveChartType(activeType);
    setChartOptions({
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        type: activeType
      }
    });
  }

  const handleChangePeriods = (date) => {
    // Retrieves the Day.js values (Ant Design uses Day.js)
    let [startDate, endDate] = date;

    // The names of our periodTypes are the same as the available units for Day.js (year, quarter, month, week etc.)
    // The only unit not supported by Day.js is "half", which would be a period of half a year
    switch (periodType) {
      case 'half':
        startDate = startDate.month() > 6 ? startDate.startOf('year').add(6, 'month') : startDate.startOf('year');
        endDate = endDate.month() > 6 ? endDate.endOf('year') : endDate.endOf('year').subtract(6, 'month');
        break;
      default:
        startDate = startDate.startOf(periodType);
        endDate = endDate.endOf(periodType);
    }

    setChartPeriodStart(startDate);
    setChartPeriodEnd(endDate);
  }

  if (!!!element)
    return null;

  return (
    <div className={"benchmark-chart-wrapper"}>
      <div className={"benchmark-chart-header"}>
        <span className={"benchmark-chart-indicator"}>{element.webform_indicator}</span>
      </div>

      <div className={"benchmark-chart-content"}>
        <div className={"benchmark-chart-content-header"}>
          <div className={"benchmark-chart-header-info"}>
            <div className={"benchmark-chart-header-info-group"}>
              <p className={'text'}>{t('your_value')}</p>
              <p className={'value'}>{value || '-'}</p>
            </div>

            {!!groups.length && (
              <div className={"benchmark-chart-header-info-group"}>
                <p className={'text'}>{t('total_groups')}</p>
                <p className={'value'}>{groups.length}</p>
              </div>
            )}

            {!!organizations.length && (
              <div className={"benchmark-chart-header-info-group"}>
                <p className={'text'}>{t('total_organizations')}</p>
                <p className={'value'}>{organizations.length}</p>
              </div>
            )}
          </div>

          <div className={"benchmark-chart-header-filters"}>
            <div className={"benchmark-chart-filter"}>
              <RangePicker 
                disabled={[loadingChart, loadingChart]}
                onChange={handleChangePeriods} 
                picker={periodTypes.includes(periodType) ? periodType : periodTypes[1]}
                value={[chartPeriodStart, chartPeriodEnd]}
              />
            </div>

            <div className={"benchmark-chart-filter"}>
              <p className={'benchmark-chart-filter-title'}>{t('show')}</p>
              <Select disabled={loadingChart} onChange={handleChangeActiveType} style={{width: "100px"}} value={activeChartType}>
                  {chartTypes?.map((item) => {
                    if (item === 'line' && settingsBenchmark?.charts?.show_line_charts !== 'yes')
                      return null;

                    return (<Option key={item} value={item}>{`${item[0].toUpperCase()}${item.slice(1)}`}</Option>);
                  })}
              </Select>
            </div>
          </div>
        </div>

        <div className={"benchmark-chart"}>
          {loadingChart || chartOptions === null ? (
            <div style={{alignItems: 'center', display: "flex", justifyContent: 'center', minHeight: '400px', width: '100%'}}>
              <Spin size="large"/>
            </div>
          ) : (
            <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
          )}
        </div>

        {!!element.description?.length ? (
          <div className={"benchmark-chart-content-footer"}>
            <p className={"benchmark-chart-content-label"}>{t('description')}:</p>
            <p className={"benchmark-chart-content-description"}>{element.description}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default withTranslation("benchmark")(ModalBenchmarkChart);