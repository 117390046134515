import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {Menu} from 'antd'
import ProfileOutlined from "@ant-design/icons/lib/icons/ProfileOutlined";
import FileProtectOutlined from "@ant-design/icons/lib/icons/FileProtectOutlined";
import {withTranslation} from 'react-i18next'

import history from '../../history'

import {api} from '../../services'

import PaperWrite from '../../assets/images/svg/paperWrite.jsx'
import House from '../../assets/images/svg/house.jsx'
import DataFile from '../../assets/images/svg/dataFile.jsx'
import HelpWheel from '../../assets/images/svg/helpWheel.jsx'
import UserImg from '../../assets/images/svg/userImg.jsx';
import BenchmarkImg from '../../assets/images/svg/benchmarkIcon.jsx';

const Sidebar = props => {
  const [pages, setPages] = useState([])
  const [reportblocks, setReportBlocks] = useState([])
  const [menuOpen, setMenuOpen] = useState(true)

  const {app: {settings}, user: {isGuest, data: {page_settings, organisation, permissions, scopes, can_edit_users }}, hideMenu} = props

  const items = [
    {
      key: '/',
      url: '/',
      icon: <House setActive={props.location.pathname === '/'}/>,
      title: props.t('home'),
      show: true,
    },
    {
      key: '/file-manager',
      url: `/file-manager`,
      icon: <ProfileOutlined style={{ marginRight: 0}} />,
      title: props.t('file_manager'),
      show: !isGuest && !settings?.hide_upload_files && scopes?.find(i => i === 'file_view'),
    },
    {
      key: '/profile',
      url: '/profile',
      icon: <UserImg />,
      title: props.t('users'),
      show: !isGuest && !settings?.hide_users_management && can_edit_users,
    },
    {
      key: '/dashboard',
      url: '/dashboard',
      icon: <DataFile setActive={props.location.pathname === '/dashboard'}/>,
      title: props.t('reports'),
      show: !isGuest && !settings?.hide_reports_menu,
    },
    {
      key: '/questionnaires',
      url: '/questionnaires',
      icon: <PaperWrite
        setActive={props.location.pathname.slice(0, 16) !== '/questionnaires/' ? (props.location.pathname === '/questionnaires') : true}/>,
      title: props.t('questionnaires'),
      show: !isGuest,
    },
    {
      key: '/benchmark',
      url: '/benchmark',
      icon: <BenchmarkImg
        style={{ width: '15px', height: '15px', marginRight: 0 }}
      />,
      title: 'Benchmark',
      show: !isGuest && !settings?.hide_benchmarks_menu,
    },
    {
      key: '/help',
      url: '/help',
      icon: <HelpWheel setActive={props.location.pathname === '/help'} />,
      title: 'Help Center',
      show: !isGuest && !settings?.hide_help_center,
    },
    // {
    //   key: '/benchmark-old',
    //   url: '/benchmark-old',
    //   icon: <GroupOutlined
    //     setActive={props.location.pathname === '/benchmark-old'}
    //     style={{ width: '15px', height: '15px', marginRight: 0 }}
    //   />,
    //   title: props.t('benchmark'),
    //   show: view_benchmarks_menu,
    // }
  ]

  useEffect(() => {
    api.static_pages.getStaticPages()
      .then(result => {
        if (result.data) {
          const data = result.data['static-pages'].filter((item) => {
            return isGuest ? settings.guest_mode.static_page?.indexOf(item.id) !== -1 : (page_settings.is_enabled ? page_settings.static_page?.indexOf(item.id) !== -1 : true)
          })

          setPages(data)
        }
      })
    api.report_blocks.getReportBlocks()
      .then(result => {
        if (result.data?.nodes) {
          const data = result.data.nodes.filter((item) => {
            return isGuest ? settings.guest_mode.basic_page_report_blocks?.indexOf(item.id) !== -1 : (page_settings.is_enabled ? page_settings.basic_page_report_blocks?.indexOf(item.id) !== -1 : true)
          })

          setReportBlocks(data)
        }
      })
  }, [])


  return (
    <div className={`left-side-menu ${!menuOpen && 'collapsed'}`}>
      <div style={{ overflow: 'hidden auto'}}>
        <Menu
          selectedKeys={
            [
              (props.location.pathname.slice(0, 16) === '/questionnaires/' ? '/questionnaires' : props.location.pathname),
              (props.location.pathname.slice(0, 11) === '/benchmark/' ? '/benchmark' : props.location.pathname),
              (props.location.pathname.slice(0, 14) === '/file-manager/' ? '/file-manager' : props.location.pathname),
              (props.location.pathname.slice(0, 9) === '/profile/' ? '/profile' : props.location.pathname)
            ]
          }
          mode='inline'
        >
          {items.map(i =>
            i.show &&
            <Menu.Item key={i.key} onClick={() => history.push(i.url)}>
              {i.icon}
              <span className={'item__name'} style={{marginLeft: 10, color: '#21416f !important'}}>{i.title}</span>
            </Menu.Item>
          )}
          <div className="ant-menu-item-group-title"></div>
          {menuOpen && !settings?.hide_reports_menu && reportblocks.map(i => <Menu.Item key={'/report-block/' + i.id} style={{ whiteSpace: 'normal', height: '100%' }}
                                            onClick={() => history.push('/report-block/' + i.id)}>{i.title}</Menu.Item>)}
          <div className="ant-menu-item-group-title"></div>
          {menuOpen && !settings?.hide_reports_menu && pages.map(i => <Menu.Item key={'/pages/' + i.id} style={{ whiteSpace: 'normal', height: '100%' }}
                                     onClick={() => history.push('/pages/' + i.id)}>{i.title}</Menu.Item>)}

        </Menu>
        {/*<Menu mode='inline'>*/}
        {/*  <Menu.ItemGroup key='report-blocks'>*/}
        {/*    */}
        {/*  </Menu.ItemGroup>*/}
        {/*  <Menu.ItemGroup key='pages'>*/}
        {/*    */}
        {/*  </Menu.ItemGroup>*/}
        {/*</Menu>*/}
      </div>
      <button className='collapse-button' onClick={() => {
        hideMenu(menuOpen);
        setMenuOpen(!menuOpen)
      }}
              title={`${menuOpen ? 'Collapse side menu' : 'Expand side menu'}`}>
        <i className={`fas ${menuOpen ? 'fa-angle-double-left' : 'fa-angle-double-right'}`} />
      </button>
    </div>
  )
}

export default withTranslation()(connect()(Sidebar))
